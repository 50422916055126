import service from '@/api/service'
const apiName = '/api/contratos'
export default {
  namespaced: true,
  actions: {
    async getContratos ({ context }, payload) {
      let retval = {}
      try {
        const { data } = await service({
          _method: 'GET',
          url: `${apiName}?condominio_id=${payload.proyecto_id}`
        })
        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }
      return retval
    },
    async getContrato ({ context }, payload) {
      let retval = {}
      try {
        const { data } = await service({
          _method: 'GET',
          url: `${apiName}/${payload.proyecto_id}?condominio_id=${payload.proyecto_id}&num_catastral=${payload.num_catastral}`
        })
        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }
      return retval
    },
    async setPlantillaContrato ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await service.put(`${apiName}/${payload.departamento_id}`, payload)
        retval = data
      } catch (error) {
        retval = { error: false, ...error.response.data }
      } finally {
      }

      return retval
    },
    async setPlantillaProyecto ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await service.put(`${apiName}`, payload)
        retval = data
      } catch (error) {
        retval = { error: false, ...error.response.data }
      } finally {
      }

      return retval
    },
    async generarContrato ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${apiName}/contrato?condominio_id=${payload.proyecto_id}&num_catastral=${payload.num_catastral}`,
          responseType: 'blob'
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },
    async generarContratosProyecto ({ context }, payload) {
      let retval = {}
      try {
        const { data } = await service({
          _method: 'GET',
          url: `${apiName}/generar-contratos?condominio_id=${payload.proyecto_id},
          responseType: 'blob'`
        })
        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }
      return retval
    }
  }
}
