import service from '@/api/service'

const apiName = '/api/archivos'
// const modName = 'archivosModule'

export default {
  namespaced: true,

  actions: {
    async download ({ context }, archivo_id) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${apiName}/download?archivo_id=${archivo_id}`,
          responseType: 'blob'
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
