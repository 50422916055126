<template>
  <div>
    <div class="sidenav-menu-heading">Inversionistas</div>

    <sidebar-link
      :requiresAuthorizations="['inversionistas indice datos contacto']"
      :to="{ name: 'DatosContactoInversionistaView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="users"></i>
      </div>
      Datos de contacto
    </sidebar-link>

    <sidebar-link
      :requiresAuthorizations="['inversionistas indice propiedades']"
      :to="{ name: 'DepartamentosInversionistaView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="columns"></i>
      </div>
      Mis Departamentos
    </sidebar-link>

    <sidebar-link
      :requiresAuthorizations="['inversionistas indice facturas']"
      :to="{ name: 'FacturasInversionistasView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="folder"></i>
      </div>
      Mis Comprobantes
    </sidebar-link>

    <sidebar-link
      :requiresAuthorizations="['inversionistas indice documentos']"
      :to="{ name: 'DocumentosInversionistasView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="file-text"></i>
      </div>
      Mis Documentos
    </sidebar-link>
  </div>
</template>

<script>
import SidebarLink from './SidebarLink'

export default {
  name: 'SidebarSectionInversionistas',

  components: {
    SidebarLink
  }
}
</script>

<style scoped>

</style>
