import Resource from '@/api/Resource'
import service from '@/api/service'

const apiName = '/api/rentas'
const modName = 'rentaModule'
const resource = new Resource(apiName, modName)

export default {
  namespaced: true,

  state: {
    /**
     * Almacena todos los usuarios del sistema.
     */
    resource: null,

    resourceRentas: null,

    /**
     * Cuando se obtiene la información de un usuario a actualizar,
     * se almacena en esta variable, se declara como nulo inicialmente
     * para saber si actualmente se está editando o no un usuario.
     */
    editingResource: null,

    /**
     * Término ingresado para buscar un usuario.
     */
    query: null,

    isLoadingResource: false,

    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false,

    isGettingRentas: false,

    showRentasModal: false
  },

  getters: {
    isThereData: ({ resource }) => Boolean(resource && resource.data),

    getResourceData: (state, getters) => (getters.isThereData ? state.resource.data : []),

    getResource: (state, getters) => (getters.isThereData ? state.resource : {}),

    /**
     * 'isEditingResource'.
     *
     * Indica si actualmente se está o no editando un recurso.
     */
    isEditingResource: state => Boolean(state.editingResource),

    /**
     * 'getAvailableList'.
     *
     * Indica si la lista de usuarios está disponible o no,
     * usado por ejempolo cuando se está haciendo una petición remota,
     * o cuando se está eliminando un elemento e indicar al usuario que
     * se está realizando actualmente una acción.
     *
     * @returns {Boolean} Al ejecutar acciones como 'crear', 'editar' o 'eliminar'.
     */
    getAvailableList: state => {
      return !state.isCreatingResource && !state.isGettingResource && !state.isDeletingResource
    },

    /**
     * 'isSearchingMode'.
     *
     * Indica si hay un texto de búsqueda para filtrado de usuarios.
     */
    isSearchingMode: state => Boolean(state.query)
  },

  mutations: {
    /**
     * 'resetState'.
     *
     * Elimina todas las variables del state, las formatea a nulo.
     */
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setResource (state, resource) {
      state.resource = resource
    },

    setResourceRentas (state, resource) {
      state.resourceRentas = resource
    },

    setResourceToEdit (state, resource) {
      state.editingResource = resource
    },

    setIsGettingResource (state, value) {
      state.isGettingResource = value
    },

    setIsGettingRentas (state, value) {
      state.isGettingRentas = value
    },

    setIsCreatingResource (state, value) {
      state.isCreatingResource = value
    },

    setIsDeletingResource (state, value) {
      state.isDeletingResource = value
    },

    setQuery (state, payload) {
      state.query = payload
    },

    setShowRentasModal (state, value) {
      state.showRentasModal = value
    }
  },

  actions: {
    /**
     * Hace una petición al servidor para obtener todos usuarios.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getResource ({ commit }, periodo_id) {
      const { error, message, data } = await resource.get(`${apiName}?periodo_id=${periodo_id}`, null, 'setIsGettingResource')

      if (error) return { error, message }

      commit('setResource', data)

      return data
    },
    async getPropiedades ({ commit }, periodo_id) {
      const { error, message, data } = await resource.get(`${apiName}/facturas?periodo_id=${periodo_id}`)

      if (error) return { error, message }

      return data
    },

    /**
     * exportar.
     *
     * Exportación a Excel del listado de pagos programados de acuerdo al período
     */
    async export ({ context }, periodo) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${apiName}/export?periodo_id=${periodo}`,
          responseType: 'blob',
          params: {
            periodo
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Importa un archivo layout para actualización de la programación de pagos
     *
     * @param {file}        payload.file
     * @param {int}         payload.nomina_id
     * @param {int}         payload.periodo_fiscal_id
     * @param {Boolean}     payload.save_file
     * @param {Boolean}     payload.overwrite
     */
    async import ({ dispatch }, payload) {
      let retval = {}

      try {
        const formData = new FormData()

        formData.append('file', payload.file)
        formData.append('periodo_id', payload.periodo_id)
        formData.append('save_file', 0)

        const { data } = await service.post(`${apiName}/import`, formData)

        retval = data

        dispatch('getResource', payload.periodo_id)
      } catch (error) {
        retval = { error: false, ...error.response.data }
      }

      return retval
    },

    async getRentas ({ commit }, id) {
      const { error, message, data } =
        await resource.get(`${apiName}/${id}/rentas`, null, 'setIsGettingRentas')

      if (error) return { error, message }

      commit('setResourceRentas', data)

      return data
    },

    async search ({ commit }, { search, page }) {
      const { error, message, data } = await resource.get(
        `${apiName}/search`,
        { search, page },
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setResource', data.data)

      return data
    },

    /**
     * Petición asíncrona para crear un nuevo usuario.
     *
     * @param {string} payload.email Email del usuario
     * @param {string} payload.password Contraseña del usuario
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async createResource ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await resource.create(payload)

        retval = data

        dispatch('getResource')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Obtiene la información de un usuario en específico.
     *
     * Se obtiene un usuario basado en su id y la información
     * se guarda en 'setResourceToEdit'.
     *
     * @param {int} id Id del usuario a obtener.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getResourceToEdit ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await resource.show(id)

        retval = data

        commit('setResourceToEdit', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Actualiza la información de un usuario.
     *
     * @param {int} id Id del usuario a actualizar.
     * @param {string} payload.nombre Nombre de la persona.
     * @param {string} payload.email Email del usuario.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async updateResource ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await resource.update(id, payload)

        retval = data

        commit('setResourceToEdit', null)

        dispatch('getResource')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Elimina la información de un usuario.
     *
     * @param {int} id Id del usuario a eliminar.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async deleteResource ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await resource.delete(id, 'setIsDeletingResource')

        retval = data

        dispatch('getResource')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
