<template>
  <base-view
    :title="authUser.identificacion"
    :periodo="periodo"
    :icon="isAdmin ? 'user-plus':'user'"
    header-type="overlap"
    :background="isAdmin ? 'bg-gradient-black-to-blue' : 'bg-gradient-light-to-black'"
  >
    <dashboard-admin v-if="$hasRoles(['admin'])"></dashboard-admin>
    <dashboard-inversionista v-if="$hasRoles(['inversionista'])"></dashboard-inversionista>
  </base-view>
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardAdmin from './Dashboards/DashboardAdmin'
import DashboardInversionista from './Dashboards/DashboardInversionista'

const PERIODO_MODULE = 'periodoModule'

export default {
  name: 'AdminDashboard',

  data () {
    return {
      actual: null
    }
  },

  computed: {
    ...mapGetters('userModule', { authUser: 'getAuthUser', isAdmin: 'isAdmin' }),
    periodo () {
      if (!this.actual) return ''
      return `Período: ${this.actual.anio} - ${this.actual.mes}`
    }
  },

  components: {
    DashboardAdmin,
    DashboardInversionista
  },

  created () {
    this.periodoActual()
  },

  methods: {
    async periodoActual () {
      // Obtenemos el período que corresponda al mes y año actual
      const result1 = await this.$store.dispatch(`${PERIODO_MODULE}/getPeriodoActual`)
      if (result1.error) {
        const { error, message } = result1

        this.$notify({ error, message }, 'Rentas')
        return
      }
      this.actual = result1.data.periodo
    }
  }
}
</script>

<style lang="scss" scoped></style>
