<template>
  <div>
    <div class="sidenav-menu-heading">Sistema de Reglas</div>

    <!-- Relación de Reglas -->
    <sidebar-link
      :requiresAuthorizations="['indice reglas']"
      :to="{ name: 'SeReglasMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="clipboard"></i>
      </div>
      Reglas
    </sidebar-link>

    <!-- Estructura del CFDI -->
    <sidebar-link
      :requiresAuthorizations="['indice estructura-cfdi']"
      :to="{ name: 'SeEstructuraCfdiMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="columns"></i>
      </div>
      Estructura CFDI
    </sidebar-link>

    <!-- Reglas CFDI -->
    <sidebar-link
      :requiresAuthorizations="['indice reglas-cfdi']"
      :to="{ name: 'SeReglasEstructuraCfdiMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="check-square"></i>
      </div>
      Reglas CFDI
    </sidebar-link>

    <!-- Reglas SAT -->
    <sidebar-link
      :requires-authorizations="['indice reglas-sat']"
      :to="{ name: 'reglas-sat' }"
      :show-permission-help="false"
      >
      <div class="nav-link-icon">
        <i data-feather="folder-plus"></i>
      </div>
      Reglas SAT
    </sidebar-link>
  </div>
</template>

<script>
import SidebarLink from './SidebarLink'

export default {
  name: 'SidebarSectionReglas',

  props: {
    activeAccordeon: {
      required: true
    }
  },

  components: {
    SidebarLink
  }
}
</script>

<style scoped>

</style>
