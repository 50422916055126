import Resource from '@/api/Resource'

const apiName = '/api/listados'
const modName = 'listadoModule'
const resource = new Resource(apiName, modName)

export default {
  namespaced: true,

  state: {
    /**
     * Almacena todos los usuarios del sistema.
     */
    resource: null,

    isLoadingResource: false,

    isGettingResource: false
  },

  getters: {
    isThereData: ({ resource }) => Boolean(resource && resource.data),

    getResourceList: ({ resource }, getters) => (getters.isThereData ? resource.data : []),

    getResource: ({ resource }, getters) => (getters.isThereData ? resource : {}),

    getTotalItems: (state, getters) => (getters.isThereData ? state.resource.data.length : 0),

    /**
     * 'getAvailableList'.
     *
     * Indica si la lista de usuarios está disponible o no,
     * usado por ejempolo cuando se está haciendo una petición remota,
     * o cuando se está eliminando un elemento e indicar al usuario que
     * se está realizando actualmente una acción.
     *
     * @returns {Boolean} Al ejecutar acciones como 'crear', 'editar' o 'eliminar'.
     */
    getAvailableList: state => {
      return !state.isCreatingResource && !state.isGettingResource && !state.isDeletingResource
    }
  },

  mutations: {
    /**
     * 'resetState'.
     *
     * Elimina todas las variables del state, las formatea a nulo.
     */
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setResource (state, resource) {
      state.resource = resource
    },

    setIsGettingResource (state, value) {
      state.isGettingResource = value
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    async getRetenciones ({ state, getters, commit, dispatch }, { inversionista_id, periodo_id, page = null }) {
      const { error, message, data } =
        await resource.get(
          'api/listados/retenciones',
          { inversionista_id, periodo_id, page },
          'setIsGettingResource'
        )

      if (error) return { error, message }

      commit('setResource', data)

      return data
    },

    async getPropiedadesPagadas ({ state, getters, commit, dispatch }, { enviaron_factura, page = null }) {
      const { error, message, data } =
        await resource.get(
          'api/listados/propiedades',
          { enviaron_factura, page },
          'setIsGettingResource'
        )

      if (error) return { error, message }

      commit('setResource', data)

      return data
    }
  }
}
