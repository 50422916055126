<template>
  <div>
    <div class="sidenav-menu-heading">Documentación</div>

    <!-- Sidenav Accordion (Documentación de Empresas e Inversionistas)-->
    <sidebar-link
      :requiresAuthorizations="['indice documentos']"
      :to="{ name: 'DocumentosMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="file"></i>
      </div>
      Documentos Inversionistas
    </sidebar-link>

    <sidebar-link
      :requiresAuthorizations="['indice documentos']"
      :to="{ name: 'DocumentosEmpresaMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="file"></i>
      </div>
      Documentos Empresas
    </sidebar-link>
  </div>
</template>

<script>
import SidebarLink from './SidebarLink'

export default {
  name: 'SidebarSectionDocumentacion',

  components: {
    SidebarLink
  }
}
</script>

<style scoped>

</style>
