import $ from 'jquery'
import service from '@/api/service'

export default {
  namespaced: true,

  state: {
    months: [
      { id: 1, value: 'ENERO' },
      { id: 2, value: 'FEBRERO' },
      { id: 3, value: 'MARZO' },
      { id: 4, value: 'ABRIL' },
      { id: 5, value: 'MAYO' },
      { id: 6, value: 'JUNIO' },
      { id: 7, value: 'JULIO' },
      { id: 8, value: 'AGOSTO' },
      { id: 9, value: 'SEPTIEMBRE' },
      { id: 10, value: 'OCTUBRE' },
      { id: 11, value: 'NOVIEMBRE' },
      { id: 12, value: 'DICIEMBRE' },
      { id: 13, value: 'RECURRENTE' },
      { id: 14, value: 'INCIDENTAL' }
    ],

    sidebarActive: true,

    notifications: [],

    allNotificationsShowed: false,

    notificationLoading: false
  },

  getters: {
    getMonths: state => state.months,

    getMonthById: state => id => state.months.find(el => el.id === id),

    getNotifications: state => state.notifications,

    //
    // Valida si hay notificaciones, sin filtrar, sin importar si están leídas o no.
    isThereNotifications: state => state.notifications.length > 0,

    //
    // Obtiene el total de las notificaciones sin leer
    getNotificationsCount: (state, getters) => getters.getUnReadNotifications.length,

    //
    // Obtiene las notificaciones sin leer
    getUnReadNotifications: state => state.notifications.filter(e => !e.notificated),

    //
    // Pregunta si todas las notificaciones sin leer ya se han mostrado inicialmente
    isAllNotificationsShowed: state => state.allNotificationsShowed,

    //
    // Pregunta si existe un proceso de carga de notificación
    // entiéndase cuando se está marcando la notificación como leída
    isLoadingNotification: state => state.notificationLoading
  },

  mutations: {
    toggleSidebar (state) {
      $('body').toggleClass('sidenav-toggled')
      state.sidebarActive = !state.sidebarActive
    },

    setSidebarActive (state, payload) {
      if (!payload && state.sidebarActive) {
        $('body').toggleClass('sidenav-toggled')
      }

      state.sidebarActive = payload
    },

    setAllNotificationsAsShowed (state, payload) {
      state.allNotificationsShowed = payload
    },

    setLoadingNotification (state, payload) {
      state.notificationLoading = payload
    }
  },

  actions: {
    /**
     * TODO: BORRAR MÉTODO
     *
     * Crea una notificación de prueba
     */
    async createNotification () {
      let retval = null

      try {
        retval = await service.get('api/notifications/create')

        console.log('retval :>> ', retval)
      } catch (error) {
        console.log('error :>> ', error)
      }

      return retval
    },

    /**
     * Marca la notificación como "leída".
     *
     * @param {Number} id Id de la notificación para marcar como "leída".
     *
     * @returns
     */
    async markAsRead ({ commit }, id) {
      let retval = {}

      try {
        commit('setLoadingNotification', true)
        retval = await service.put(`api/notifications/${id}`)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      } finally {
        commit('setLoadingNotification', false)
      }

      return retval
    },

    markAllNotificationsAsShowed ({ commit }) {
      commit('setAllNotificationsAsShowed', true)
    }
  }
}
