<template>
  <div>
    <div class="sidenav-menu-heading">Administración</div>

    <!-- Sidenav Accordion (Usuarios y permisos)-->
    <a
      class="nav-link my-cursor"
      :class="activeAccordeon === 'usersAndPermissions' ? '' : 'collapsed'"
      data-toggle="collapse"
      data-target="#collapseDashboards"
      :aria-expanded="activeAccordeon === 'usersAndPermissions' ? true : false"
      aria-controls="collapseDashboards"
    >
      <div class="nav-link-icon"><i data-feather="key"></i></div>
      Usuarios y permisos
      <div class="sidenav-collapse-arrow">
        <i class="fas fa-angle-down"></i>
      </div>
    </a>
    <div
      :class="activeAccordeon === 'usersAndPermissions' ? 'collapse show' : 'collapse'"
      id="collapseDashboards"
      data-parent="#accordionSidenav"
    >
      <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPages">
        <sidebar-link
          :requiresAuthorizations="['acceso usuarios']"
          :to="{ name: 'Users', params: { tabActive: 'users' } }"
        >
          Usuarios
        </sidebar-link>

        <sidebar-link
          :requiresAuthorizations="['acceso roles']"
          :to="{ name: 'Roles', params: { tabActive: 'roles' } }"
        >
          Roles
        </sidebar-link>

        <sidebar-link
          :requiresAuthorizations="['acceso permisos']"
          :to="{ name: 'Permissions', params: { tabActive: 'permissions' } }"
        >
          Permisos
        </sidebar-link>
      </nav>
    </div>

    <sidebar-link
      :requiresAuthorizations="['indice periodos']"
      :to="{ name: 'PeriodosMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="calendar"></i>
      </div>
      Períodos
    </sidebar-link>

    <sidebar-link
      :requiresAuthorizations="['indice inpc']"
      :to="{ name: 'INPCMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="percent"></i>
      </div>
      INPC
    </sidebar-link>

    <sidebar-link
      :requires-authorizations="['indice proyecciones']"
      :to="{ name: 'proyecciones' }"
      :show-permission-help="true"
    >
      <div class="nav-link-icon">
        <i data-feather="trending-up"></i>
      </div>
      Proyecciones
    </sidebar-link>

    <!-- <sidebar-link
      :requiresAuthorizations="['indice reglas']"
      :to="{ name: 'ReglasMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="check-square"></i>
      </div>
      Reglas
    </sidebar-link> -->

    <sidebar-link
      :requiresAuthorizations="['indice plantillas contratos']"
      :to="{ name: 'PlantillasContratosMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="edit"></i>
      </div>
      Plantillas para Contratos
    </sidebar-link>
  </div>
</template>

<script>
import SidebarLink from './SidebarLink'

export default {
  name: 'SidebarSectionAdministracion',

  props: {
    activeAccordeon: {
      required: true
    }
  },

  components: {
    SidebarLink
  }
}
</script>

<style scoped>

</style>
