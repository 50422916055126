import Resource from '@/api/Resource'
const resource = new Resource('/api/reglas-sat', 'reglasSatModule')

export default {
  namespaced: true,

  state: {
    /**
     * Representan todos los permisos disponibles del sistema.
     *
     * Están en un formato para hacer un checklist para poder
     * listar y actualizar fácilmente.
     */
    resource: null,

    isCreating: false,

    reglas: null,

    regla_id: null
  },

  getters: {
    getData: ({ resource }) => resource ? resource.data : [],

    getResourceData: (state, getters) => (getters.isThereData ? state.resource.data : []),

    getResourceList: ({ resource }) => resource ? resource.data : [],

    getReglasList: ({ reglas }) => reglas ? reglas.data : [],

    getAvailableList: state => {
      return resource
    }
  },

  mutations: {
    setResource (state, payload) {
      state.resource = payload
    },
    setReglas (state, payload) {
      state.reglas = payload
    },
    setReglaId (state, payload) {
      state.regla_id = payload
    },
    setIsCreatingResource (state, payload) {
      state.isCreating = payload
    }
  },

  actions: {
    /**
     * Obtiene todos los permisos.
     *
     * Ejecuta la acción para recuperar todos los permisos,
     * cuando la respuesta es satisfactoria, ejecuta una mutación
     * para almacenarlos localmente.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getResource ({ commit }) {
      let retval = {}

      try {
        const { data } = await resource.get('/api/reglas-sat')
        retval = data
        commit('setResource', data)
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    async getReglas ({ state, commit }, payload) {
      let retval = {}
      if (payload.regla_id) {
        commit('setReglaId', payload.regla_id)
      }

      try {
        const { data } = await resource.get(`/api/reglas-sat/reglas?regla_id=${state.regla_id}`)
        retval = data
        commit('setReglas', data)
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    async createResource ({ state, commit, dispatch }, payload) {
      let retval = {}

      try {
        const formData = new FormData()
        formData.append('catalogo_id', payload.catalogo_id)
        formData.append('regla_id', payload.regla_id)
        formData.append('clave', payload.clave)
        const { data } = await resource.create(formData, 'setIsCreatingResource')
        retval = data
        dispatch('getReglas', state.regla_id)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
