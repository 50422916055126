import Resource from '@/api/Resource'

const apiName = '/api/inversionistas/departamentos'
const modName = 'departamentosInversionistaModule'
const resource = new Resource(apiName, modName)

export default {
  namespaced: true,

  state: {
    /**
     * Almacena todos los usuarios del sistema.
     */
    resource: null,

    /**
     * Cuando se obtiene la información de un usuario a actualizar,
     * se almacena en esta variable, se declara como nulo inicialmente
     * para saber si actualmente se está editando o no un usuario.
     */
    editingResource: null,

    /**
     * Término ingresado para buscar un usuario.
     */
    query: null,

    isLoadingResource: false,

    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false
  },

  getters: {
    isThereData: ({ resource }) => Boolean(resource && resource.data),

    getResourceList: ({ resource }, getters) => (getters.isThereData ? resource.data : []),

    getResource: ({ resource }, getters) => (getters.isThereData ? resource : {}),

    /**
     * 'isEditingResource'.
     *
     * Indica si actualmente se está o no editando un recurso.
     */
    isEditingResource: state => Boolean(state.editingResource),

    /**
     * 'getAvailableList'.
     *
     * Indica si la lista de usuarios está disponible o no,
     * usado por ejempolo cuando se está haciendo una petición remota,
     * o cuando se está eliminando un elemento e indicar al usuario que
     * se está realizando actualmente una acción.
     *
     * @returns {Boolean} Al ejecutar acciones como 'crear', 'editar' o 'eliminar'.
     */
    getAvailableList: state => {
      return !state.isCreatingResource && !state.isGettingResource && !state.isDeletingResource
    },

    /**
     * 'isSearchingMode'.
     *
     * Indica si hay un texto de búsqueda para filtrado de usuarios.
     */
    isSearchingMode: state => Boolean(state.query)
  },

  mutations: {
    /**
     * 'resetState'.
     *
     * Elimina todas las variables del state, las formatea a nulo.
     */
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setResource (state, resource) {
      state.resource = resource
    },

    setResourceToEdit (state, resource) {
      state.editingResource = resource
    },

    setIsGettingResource (state, value) {
      state.isGettingResource = value
    },

    setIsCreatingResource (state, value) {
      state.isCreatingResource = value
    },

    setIsDeletingResource (state, value) {
      state.isDeletingResource = value
    },

    setQuery (state, payload) {
      state.query = payload
    }
  },

  actions: {
    /**
     * Hace una petición al servidor para obtener todos usuarios.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getResource ({ state, getters, commit, dispatch }, page = null) {
      if (getters.isSearchingMode) return dispatch('search', { search: state.query, page })

      const { error, message, data } =
        page !== null
          ? await resource.get(`${apiName}?page=${page}`, null, 'setIsGettingResource')
          : await resource.all({}, 'setIsGettingResource')

      if (error) return { error, message }

      commit('setResource', data)

      return data
    },

    async search ({ commit }, { search, page }) {
      const { error, message, data } = await resource.get(
        `${apiName}/search`,
        { search, page },
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setResource', data.data)

      return data
    },

    /**
     * Obtiene la información de un usuario en específico.
     *
     * Se obtiene un usuario basado en su id y la información
     * se guarda en 'setResourceToEdit'.
     *
     * @param {int} id Id del usuario a obtener.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getResourceToEdit ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await resource.show(id)

        retval = data

        commit('setResourceToEdit', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
