<template>
  <div v-if="includeFilter(value, filter)" :class="'text-sm ' + (centered ? 'text-center ':' ') + 'text-primary w-100'">
    {{ leftContent(value, filter) }}<b style="background-color: yellow;">{{ filterContent(value, filter) }}</b>{{ rightContent(value, filter) }}
  </div>
  <div v-else :class="'text-sm ' + (centered ? 'text-center ':' ') + 'w-100'">
    {{ value }}
  </div>
</template>

<script>
export default {
  name: 'FilteredRowTemplate',

  props: {
    filter: {
      required: true
    },

    value: {
      required: true
    },

    centered: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    includeFilter (value, filter) {
      if ((filter === null) || (filter === '') || (value === null)) {
        return false
      }
      return value.toLowerCase().includes(filter.toLowerCase())
    },

    leftContent (value, filter) {
      return value.substring(0, value.toLowerCase().indexOf(filter.toLowerCase()))
    },

    filterContent (value, filter) {
      return value.substring(
        value.toLowerCase().indexOf(filter.toLowerCase()),
        value.toLowerCase().indexOf(filter.toLowerCase()) + filter.length)
    },

    rightContent (value, filter) {
      return value.substring(value.toLowerCase().indexOf(filter.toLowerCase()) + filter.length)
    }
  }
}
</script>
