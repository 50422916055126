<template>
  <span class="text-sm text-center w-100">
    <span v-if="vencimiento <= 3" class="text-red">
      <alert-octagon-icon v-if="icon" class="align-baseline"></alert-octagon-icon>
      {{ data_item }}
    </span>
    <span v-else-if="vencimiento > 3 && vencimiento <= 6" class="text-orange">
      <alert-triangle-icon v-if="icon" class="align-baseline"></alert-triangle-icon>
      {{ data_item }}
    </span>
    <span v-else-if="vencimiento > 6 && vencimiento <= 8" class="text-yellow">
      <alert-circle-icon v-if="icon" class="align-baseline"></alert-circle-icon>
      {{ data_item }}
    </span>
    <span v-else-if="vencimiento > 8" class="text-green">
      <alert-circle-icon v-if="icon" class="align-baseline"></alert-circle-icon>
      {{ data_item }}
    </span>
  </span>
</template>
<script>
import { AlertCircleIcon, AlertOctagonIcon, AlertTriangleIcon } from 'vue-feather-icons'
export default {
  name: 'ContratosPorVencerTextCellComponent',
  components: {
    AlertCircleIcon,
    AlertOctagonIcon,
    AlertTriangleIcon
  },
  props: {
    vencimiento: {
      required: true
    },
    data_item: {
      required: true
    },
    icon: {
      default: false,
      required: false
    }
  }
}
</script>
