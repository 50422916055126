<template>
  <b-modal
    v-model="modal_vencimiento"
    centered
    size="lg"
    scrollable
    no-close-on-backdrop
    dialog-class="x-modal-md"
  >
    <template #modal-title>
      <div class="flex flex-column">
        <div class="text-primary">Contratos por vencer en un período de 12 meses ({{ contratosPorVencerData.length }})</div>
        <div class="small">Descripción del código de colores:</div>
        <div class="flex small">
          <div class="text-red">
            <alert-octagon-icon class="align-baseline"></alert-octagon-icon>
            Rojo: vencimiento de 1 a 3 meses
          </div>
          <div class="text-orange">
            <alert-triangle-icon class="align-baseline"></alert-triangle-icon>
            Naranja: vencimiento de 3 a 6 meses
          </div>
          <div class="text-yellow">
            <alert-circle-icon class="align-baseline"></alert-circle-icon>
            Amarillo: vencimiento de 6 a 8 meses
          </div>
          <div class="text-green">
            <alert-circle-icon class="align-baseline"></alert-circle-icon>
            Verde: vencimiento de 8 a 12 meses
          </div>
        </div>
      </div>
    </template>
    <b-table
      id="contratosTable"
      v-if="!loading"
      :fields="contratosPorVencerFields"
      :items="contratosPorVencerData"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <template #cell(num_catastral)="row">
        <contratos-por-vencer-text-cell-component :data_item="row.item.num_catastral" :vencimiento="row.item.vencimiento"/>
      </template>

      <template #cell(cuenta_predial)="row">
        <contratos-por-vencer-text-cell-component :data_item="row.item.cuenta_predial" :vencimiento="row.item.vencimiento"/>
      </template>

      <template #cell(inicio_contrato)="row">
        <contratos-por-vencer-text-cell-component :data_item="row.item.inicio_contrato" :vencimiento="row.item.vencimiento"/>
      </template>

      <template #cell(termino_contrato)="row">
        <contratos-por-vencer-text-cell-component :data_item="row.item.termino_contrato" :vencimiento="row.item.vencimiento" icon="true"/>
      </template>

      <template #cell(identificacion)="row">
        <contratos-por-vencer-text-cell-component :data_item="row.item.identificacion" :vencimiento="row.item.vencimiento"/>
      </template>

      <template #cell(rfc)="row">
        <contratos-por-vencer-text-cell-component :data_item="row.item.rfc" :vencimiento="row.item.vencimiento"/>
      </template>
    </b-table>
    <loading v-else></loading>
    <template #modal-footer>
      <!-- Enviar para crear o actualizar -->
      <div class="w-100">
      </div>
    </template>
  </b-modal>
</template>

<script>
import ContratosPorVencerTextCellComponent from '../ContratosPorVencerTextCellComponent.vue'
import { AlertCircleIcon, AlertOctagonIcon, AlertTriangleIcon } from 'vue-feather-icons'
const STORE_MODULE = 'dashboardAdministradorModule'
export default {
  name: 'ModalContratosPorVencer',

  props: {
    modal: {
      required: true
    },
    periodo: {
      required: true
    }
  },

  components: {
    ContratosPorVencerTextCellComponent,
    AlertCircleIcon,
    AlertOctagonIcon,
    AlertTriangleIcon
  },

  data () {
    return {
      contratosPorVencerFields: [
        { key: 'num_catastral', label: 'No. Catastral' },
        { key: 'cuenta_predial', label: 'No. Cuenta Predial' },
        { key: 'inicio_contrato', label: 'Fecha inicio' },
        { key: 'termino_contrato', label: 'Fecha término' },
        { key: 'identificacion', label: 'Inversionista' },
        { key: 'rfc', label: 'RFC' }
      ],
      contratosPorVencerData: [],
      modal_vencimiento: false,
      loading: false
    }
  },

  watch: {
    periodo: {
      immediate: true,
      handler (value) {
        if (value) {
          this.contratos_por_vencer(value)
        }
      }
    },
    modal: {
      immediate: true,
      handler (value) {
        if (value) {
          this.modal_vencimiento = value
        }
      }
    },
    modal_vencimiento: {
      immediate: true,
      handler (value) {
        if (!value) {
          this.$emit('closed')
        }
      }
    }
  },

  methods: {
    async contratos_por_vencer (periodo) {
      this.loading = true
      const result = await this.$store.dispatch(`${STORE_MODULE}/getContratosVencimiento`, { periodo_id: periodo.id, meses: 12 })
      this.loading = false
      if (result.error) {
        const { error, message } = result
        this.$notify({ error, message }, 'Contratos')
      }
      this.contratosPorVencerData = result.data.contratos
      this.$emit('retrieveData', this.contratosPorVencerData.length)
    }
  }
}
</script>

<style scoped>

</style>
