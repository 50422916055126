import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import PortalVue from 'portal-vue'
import axios from 'axios'
import OnesignalVue from 'onesignal-vue'
import SafeTreeview from 'vue-tree-view-table'

import BaseView from '@/views/Layouts/BaseView'
import TreeMenu from '@/components/Shared/TreeMenu'
import VueFormulate from '@braid/vue-formulate'
import Loading from '@/components/Shared/Loading'
import UDControls from '@/components/Shared/UDControls'
import UDCopyControls from '@/components/Shared/UDCopyControls'
import vueFormulateConfig from '@/mixins/vueFormulateConfig'
import CheckAuthorization from '@/components/Auth/CheckAuthorization'
import NotificationAlert from '@/components/Notification/NotificationAlert'
import Paginator from '@/components/Shared/Paginator'
import XSelect from '@/components/Shared/XSelect'
import XCardWizard from '@/components/Shared/XCardWizard'
import XCardWizardBody from '@/components/Shared/XCardWizardBody'
import XPeriodoFiscalTag from '@/components/Shared/XPeriodoFiscalTag'
import XFormGroup from '@/components/Shared/XFormGroup'
import XFormFooterButtons from '@/components/Shared/XFormFooterButtons'
import XBtnWithAuth from '@/components/Shared/XBtnWithAuth'
import XModal from '@/components/Shared/XModal'
import AlertWithErrors from '@/components/Shared/AlertWithErrors'
import XLabel from '@/components/Shared/XLabel'
import XSelectPeriodoFiscal from '@/components/Shared/XSelectPeriodoFiscal'
import XSelectUnidadAdministrativa from '@/components/Shared/XSelectUnidadAdministrativa'
import XSelectPartidaPresupuestal from '@/components/Shared/XSelectPartidaPresupuestal'
import XSelectMomentoContable from '@/components/Shared/XSelectMomentoContable'
import globalMixin from '@/mixins/globalMixin'
import VueMask from 'v-mask'
import vSelect from 'vue-select'

import VueSocialauth from 'vue-social-auth'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import 'vue-select/dist/vue-select.css'
/***************************************
 | SB ADMIN PRO
 ***************************************/
import '@/assets/css/global.scss'
import '@/assets/css/sbAdminPro.css'
import '@/assets/js/scriptsSbAdmin'

/***************************************
 | GLOBAL MIXINS
 ***************************************/
Vue.mixin(globalMixin)

/***************************************
 | GLOBAL COMPONENTS
 ***************************************/
Vue.component('loading', Loading)
Vue.component('base-view', BaseView)
Vue.component('tree-menu', TreeMenu)
Vue.component('UDControls', UDControls)
Vue.component('UDCopyControls', UDCopyControls)
Vue.component('NotificationAlert', NotificationAlert)
Vue.component('CheckAuthorization', CheckAuthorization)
Vue.component('Paginator', Paginator)
Vue.component('XSelect', XSelect)
Vue.component('XCardWizard', XCardWizard)
Vue.component('XCardWizardBody', XCardWizardBody)
Vue.component('XPeriodoFiscalTag', XPeriodoFiscalTag)
Vue.component('XFormGroup', XFormGroup)
Vue.component('XFormFooterButtons', XFormFooterButtons)
Vue.component('XBtnWithAuth', XBtnWithAuth)
Vue.component('XModal', XModal)
Vue.component('XAlertWithErrors', AlertWithErrors)
Vue.component('XLabel', XLabel)
Vue.component('XSelectPeriodoFiscal', XSelectPeriodoFiscal)
Vue.component('XSelectUnidadAdministrativa', XSelectUnidadAdministrativa)
Vue.component('XSelectPartidaPresupuestal', XSelectPartidaPresupuestal)
Vue.component('XSelectMomentoContable', XSelectMomentoContable)
Vue.component('v-select', vSelect)
Vue.component(SafeTreeview.name, SafeTreeview)

Vue.use(VueFormulate, vueFormulateConfig)
Vue.use(PortalVue)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(OnesignalVue)
Vue.use(VueMask)

Vue.use(VueSocialauth, {
  providers: {
    google: {
      clientId:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_GOOGLE_PROD_KEY_ID
          : process.env.VUE_APP_GOOGLE_LOCAL_KEY_ID,
      redirectUri:
        process.env.NODE_ENV === 'production'
          ? `${process.env.VUE_APP_PROD_URL}/auth/google/callback`
          : `${process.env.VUE_APP_LOCAL_URL}/auth/google/callback`,
      prompt: 'consent',
      optionalUrlParams: ['display', 'prompt']
    }
  }
})

Vue.config.productionTip = false

Vue.prototype.$http = axios

new Vue({
  router,
  store,
  render: h => h(App),
  beforeMount () {
    this.$OneSignal.init({ appId: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_ONESIGNAL_PROD_APP_ID : process.env.VUE_APP_ONESIGNAL_LOCAL_APP_ID })
  }
}).$mount('#app')
