<template>
  <div id="layoutAuthentication">
    <div class="container d-flex justify-content-center">
      <img
        src="@/assets/img/full_logo.png"
        class="logo__img img-fluid mt-2"
        alt="banner sistema"
        style="max-height: 100px"
      />
    </div>

    <main class="container pb-2">
      <b-row class="row justify-content-center">
        <b-col
          cols="12"
          class="wrapper__login pb-3 d-flex align-items-center justify-content-center"
        >
          <!-- Basic login form-->
          <div class="login__container shadow-lg border-0 rounded-lg">
            <div class="d-flex justify-content-center">
              <!--img
                src="@/assets/img/logotipo.jpg"
                class="logo__img img-fluid mt-3"
                alt="logo modulo inversionistas"
              /-->
            </div>

            <div class="card-body">
              <!-- Portal destination -->
              <portal-target name="notificationAlertPlace" />

              <!-- DEFAULT SLOT -->
              <slot></slot>
            </div>
          </div>
        </b-col>
      </b-row>
    </main>
  </div>
</template>

<script>
export default {
  name: 'BaseAuthView',

  created () {
    this.$store.dispatch('userModule/getAuthUser')
  }
}
</script>

<style>
#layoutAuthentication {
  background-image: url('~@/assets/img/fondo.jpg') !important;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.wrapper__login {
  min-height: calc(100vh - 120px);
}

.login__container {
  z-index: 2;
  width: 90%;
  min-width: 280px;
  max-width: 400px;
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .login__container {
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
  }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .login__container {
    background-color: rgb(226, 226, 226);
  }
}

.logo__img {
  max-height: 110px;
  z-index: 10;
}
</style>
