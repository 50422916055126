<template>
  <div>
    <div class="sidenav-menu-heading">Ayuda</div>

    <sidebar-link
      :requiresAuthorizations="['acceso videos ayuda']"
      :to="{ name: 'VideosAyuda' }"
      :show-permission-help="true"
    >
      <div class="nav-link-icon">
        <i data-feather="video"></i>
      </div>
      Videos
    </sidebar-link>

  </div>
</template>

<script>
import SidebarLink from './SidebarLink'

export default {
  name: 'SidebarSectionAyuda',

  components: {
    SidebarLink
  }
}
</script>

<style scoped>

</style>
