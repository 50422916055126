import Dashboard from '@/views/DashboardView.vue'
import Login from '@/views/Auth/LoginView'

//
// Pantallas para la recuperación de contraseñas
const RecoverView = () => import('@/views/Auth/RecoverView')
const RecoverPasswordView = () => import('@/views/Auth/RecoverPasswordView')
const PasswordConfirmView = () => import('@/views/Auth/PasswordConfirmView')

//
// Pantallas del perfil
const ProfileView = () => import('@/views/Profile/ProfileView')
const Profile = () => import('@/components/Profile/Profile')
const GuiasUsuarioMainView = () => import('@/views/Administracion/GuiasUsuarioMainView')
//
// Vistas relacionadas con la sección de administración
const UsersRolesPermissionsView = () => import('@/views/Users/UsersRolesPermissionsView')
const PeriodosMainView = () => import('@/views/Administracion/PeriodosMainView')
const INPCMainView = () => import('@/views/Administracion/INPCMainView')
const SatInfoCatalogosMainView = () => import('@/views/Administracion/SatInfoCatalogosMainView')
const ProyeccionesFinancierasMainView = () => import('@/views/Administracion/ProyeccionesFinancierasMainView')
//
// Vistas del sistema de reglas
const SeReglasMainView = () => import('@/views/SE/SeReglasMainView')
const SeEstructuraCfdiMainView = () => import('@/views/SE/SeEstructuraCfdiMainView')
const SeReglasEstructuraCfdiMainView = () => import('@/views/SE/SeReglasEstructuraCfdiMainView')
const ReglasSatMainView = () => import('@/views/Administracion/ReglasSatMainView')

//
// Pantallas para mensajes y notificaciones
const NotificacionesMainView = () => import('@/views/Operaciones/NotificacionesMainView')
const NotificationsMainView = () => import('@/views/Administracion/NotificationsMainView')
const MensajesMainView = () => import('@/views/Administracion/MensajesMainView')

//
// Pantallas para plantillas
const PlantillasContratosMainView = () => import('@/views/Operaciones/PlantillasContratosMainView')

//
// Pantalla para bitacoras
const HistoryView = () => import('@/views/History/HistoryView')

//
// Vistas de catálogos
const PaisesEstadosMunicipiosView = () => import('@/views/Catalogos/PaisesEstadosMunicipiosView')
const FormasDePagoView = () => import('@/views/Catalogos/FormasDePagoView')
const CondominiosView = () => import('@/views/Catalogos/CondominiosView')
const SubcondominiosView = () => import('@/views/Catalogos/SubcondominiosView')
const EdificiosView = () => import('@/views/Catalogos/EdificiosView')
const DepartamentosView = () => import('@/views/Catalogos/DepartamentosView')
//
// Vistas de las operaciones principales del sistema
const EmpresasMainView = () => import('@/views/Operaciones/EmpresasMainView')
const InversionistasMainView = () => import('@/views/Operaciones/InversionistasMainView')
const PropiedadesMainView = () => import('@/views/Operaciones/PropiedadesMainView')
const RentasMainView = () => import('@/views/Operaciones/RentasMainView')
const FacturasMainView = () => import('@/views/Operaciones/FacturasMainView')
const HistorialPagosMainView = () => import('@/views/Operaciones/HistorialPagosMainView')
const PagosSinComprobanteMainView = () => import('@/views/Operaciones/PagosSinComprobanteMainView')
const DocumentosMainView = () => import('@/views/Operaciones/DocumentosMainView')
const DocumentosEmpresaMainView = () => import('@/views/Operaciones/DocumentosEmpresaMainView')
const CompareInvoicesMainView = () => import('@/views/Operaciones/CompareInvoicesMainView')
const ComprobantesMainView = () => import('@/views/Operaciones/ComprobantesMainView')
const ContratosMainView = () => import('@/views/Operaciones/contratos/ContratosMainView')
const ContratosProyectoView = () => import('@/views/Operaciones/contratos/ContratosProyectoView')
const ContratosProyectoDepartamentoView = () => import('@/views/Operaciones/contratos/ContratosProyectoDepartamentoView')
//
// Vistas de las operaciones de acceso a inversionistas
const DatosContactoInversionistaView = () => import('@/views/Inversionistas/DatosContactoInversionistaView')
const DepartamentosInversionistaView = () => import('@/views/Inversionistas/DepartamentosInversionistaView')
const ContratosInversionistasView = () => import('@/views/Inversionistas/ContratosInversionistasView')
const FacturasInversionistasView = () => import('@/views/Inversionistas/FacturasInversionistasView')
const DocumentosInversionistasView = () => import('@/views/Inversionistas/DocumentosInversionistasView')
//
// Vistas para listados de retenciones y propiedades pagadas/no pagadas
const ListadoRetencionesMainView = () => import('@/views/Operaciones/ListadoRetencionesMainView')
const ListadoPropiedadesMainView = () => import('@/views/Operaciones/ListadoPropiedadesMainView')
//
// Vistas para reportes
const EstadoCuentaInversionistaMainView = () => import('@/views/Reportes/EstadoCuentaInversionistaMainView')
const DirectorioInversionistasMainView = () => import('@/views/Reportes/DirectorioInversionistasMainView')
const ConcentradoGeneralMainView = () => import('@/views/Reportes/ConcentradoGeneralMainView')

//
// Pantallas para recursos de ayuda
const VideosAyuda = () => import('@/views/Ayuda/VideosAyudaView')
/**
 * Pantalla de administración para usuarios, roles y permisos.
 *
 * Nota: Rutas expuestas para exportar.
 */
const usersAndPermissionsViews = [
  {
    path: '/users',
    name: 'Users',
    component: UsersRolesPermissionsView,
    props: { tabActive: 'users' },
    meta: {
      title: 'Usuarios',
      requiresAuth: true,
      requiresPermissions: ['acceso usuarios'],
      accordeon: 'usersAndPermissions'
    }
  },

  {
    path: '/roles',
    name: 'Roles',
    component: UsersRolesPermissionsView,
    props: { tabActive: 'roles' },
    meta: {
      title: 'Roles',
      requiresAuth: true,
      requiresPermissions: ['acceso roles'],
      accordeon: 'usersAndPermissions'
    }
  },

  {
    path: '/permisos',
    name: 'Permissions',
    component: UsersRolesPermissionsView,
    props: { tabActive: 'permissions' },
    meta: {
      title: 'Permisos',
      requiresAuth: true,
      requiresPermissions: ['acceso permisos'],
      accordeon: 'usersAndPermissions'
    }
  }
]
/**
 * Pantalla de administración para países, estados y municipios.
 *
 * Nota: Rutas expuestas para exportar.
 */
const paisesEstadosAndMunicipiosViews = [
  {
    path: '/paises',
    name: 'Paises',
    component: PaisesEstadosMunicipiosView,
    props: { tabActive: 'paises' },
    meta: {
      title: 'Paises',
      requiresAuth: true,
      requiresPermissions: ['indice paises'],
      accordeon: 'paisesEstadosMunicipios'
    }
  },

  {
    path: '/estados',
    name: 'Estados',
    component: PaisesEstadosMunicipiosView,
    props: { tabActive: 'estados' },
    meta: {
      title: 'Estados',
      requiresAuth: true,
      requiresPermissions: ['indice estados'],
      accordeon: 'paisesEstadosMunicipios'
    }
  },

  {
    path: '/municipios',
    name: 'Municipios',
    component: PaisesEstadosMunicipiosView,
    props: { tabActive: 'municipios' },
    meta: {
      title: 'Municipios',
      requiresAuth: true,
      requiresPermissions: ['indice municipios'],
      accordeon: 'paisesEstadosMunicipios'
    }
  }
]

const listados = [
  {
    path: '/retenciones',
    name: 'ListadoRetenciones',
    component: ListadoRetencionesMainView,
    props: { tabActive: 'retenciones' },
    meta: {
      title: 'Listado de retenciones',
      requiresAuth: true,
      requiresPermissions: ['listado retenciones'],
      accordeon: 'listados'
    }
  },
  {
    path: '/propiedades',
    name: 'ListadoPropiedades',
    component: ListadoPropiedadesMainView,
    props: { tabActive: 'propiedades' },
    meta: {
      title: 'Listado de propiedades',
      requiresAuth: true,
      requiresPermissions: ['listado propiedades pagadas'],
      accordeon: 'listados'
    }
  }
]

const catalogosCondominios = [
  {
    path: '/proyectos',
    name: 'Proyectos',
    component: CondominiosView,
    props: { tabActive: 'condominios' },
    meta: {
      title: 'Condominios',
      requiresAuth: true,
      requiresPermissions: ['indice condominios'],
      accordeon: 'catalogos_condominios'
    }
  },
  {
    path: '/etapas',
    name: 'Etapas',
    component: SubcondominiosView,
    props: { tabActive: 'subcondominios' },
    meta: {
      title: 'Subcondominios',
      requiresAuth: true,
      requiresPermissions: ['indice subcondominios'],
      accordeon: 'catalogos_condominios'
    }
  },
  {
    path: '/edificios',
    name: 'Edificios',
    component: EdificiosView,
    props: { tabActive: 'edificios' },
    meta: {
      title: 'Edificios',
      requiresAuth: true,
      requiresPermissions: ['indice edificios'],
      accordeon: 'catalogos_condominios'
    }
  },
  {
    path: '/departamentos',
    name: 'Departamentos',
    component: DepartamentosView,
    meta: {
      title: 'Departamentos',
      requiresAuth: true,
      requiresPermissions: ['indice departamentos']
    }
  }
]

const routes = [
  /**********************************************
   * GOOGLE AUTH
   **********************************************/
  {
    path: '/auth/:provider/callback',
    component: {
      template: '<div class="auth-component"></div>'
    }
  },

  /**********************************************
   * DASHBOARD
   **********************************************/
  {
    path: '/',
    component: Dashboard,
    meta: { requiresAuth: true }
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },

  /**********************************************
   * LOGIN
   **********************************************/
  {
    path: '/login',
    name: 'Login',
    component: Login
  },

  {
    path: '/recuperar/',
    component: RecoverView,
    children: [
      {
        // Se solicita el email para el cambio de contraseña
        path: 'validacion',
        name: 'RecoverPassword',
        component: RecoverPasswordView
      },
      {
        // Se solicita la confirmación de la nueva contraseña
        path: '/recuperar/contrasena',
        name: 'PasswordConfirm',
        component: PasswordConfirmView,
        props: route => ({
          token: route.query.token
        })
      }
    ]
  },

  /**********************************************
   * USUARIO ACTUALMENTE AUTENTICADO
   **********************************************/
  {
    path: '/perfil',
    component: ProfileView,
    meta: { requiresAuth: true },
    props: route => ({ title: route.meta.title }),
    children: [
      {
        path: '/',
        name: 'Profile',
        component: Profile,
        meta: { title: 'Perfil' }
      }
    ]
  },

  /**********************************************
   * ADMINISTRACIÓN
   **********************************************/
  ...usersAndPermissionsViews,
  ...paisesEstadosAndMunicipiosViews,
  ...catalogosCondominios,
  ...listados,
  {
    path: '/periodos',
    name: 'PeriodosMainView',
    component: PeriodosMainView,
    meta: {
      requiresAuth: true,
      accordeon: 'notificaciones'
    }
  },
  {
    path: '/guias-usuario',
    name: 'guias-usuario',
    component: GuiasUsuarioMainView,
    meta: {
      requiresAuth: true,
      accordeon: 'notificaciones'
    }
  },
  {
    path: '/inpc',
    name: 'INPCMainView',
    component: INPCMainView,
    meta: {
      requiresAuth: true,
      accordeon: 'notificaciones'
    }
  },
  {
    path: '/proyecciones',
    name: 'proyecciones',
    component: ProyeccionesFinancierasMainView,
    meta: {
      requiresAuth: true,
      accordeon: 'notificaciones'
    }
  },
  {
    path: '/sat_catalogos',
    name: 'SatInfoCatalogosMainView',
    component: SatInfoCatalogosMainView,
    meta: {
      requiresAuth: true,
      accordeon: 'notificaciones'
    }
  },
  {
    path: '/formas_de_pago',
    name: 'FormasDePagoView',
    component: FormasDePagoView,
    meta: {
      requiresAuth: true,
      accordeon: 'formas_de_pago'
    }
  },

  /**********************************************
   * SISTEMA DE REGLAS
   **********************************************/
  {
    path: '/reglas',
    name: 'SeReglasMainView',
    component: SeReglasMainView,
    meta: {
      requiresAuth: true,
      accordeon: 'reglas'
    }
  },
  {
    path: '/estructura-cfdi',
    name: 'SeEstructuraCfdiMainView',
    component: SeEstructuraCfdiMainView,
    meta: {
      requiresAuth: true,
      accordeon: 'estructura_cfdi'
    }
  },
  {
    path: '/reglas-estructura-cfdi',
    name: 'SeReglasEstructuraCfdiMainView',
    component: SeReglasEstructuraCfdiMainView,
    meta: {
      requiresAuth: true,
      accordeon: 'reglas_estructura_cfdi'
    }
  },
  {
    path: '/reglas-sat',
    name: 'reglas-sat',
    component: ReglasSatMainView,
    meta: {
      requiresAuth: true,
      accordeon: 'reglas_sat'
    }
  },

  /**********************************************
   * NOTIFICACIONES
   **********************************************/
  {
    path: '/notificaciones',
    name: 'NotificacionesMainView',
    component: NotificacionesMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notifications',
    name: 'NotificationsMainView',
    component: NotificationsMainView,
    meta: {
      requiresAuth: true
    }
  },

  /**********************************************
   * MENSAJES
   **********************************************/
  {
    path: '/mensajes',
    name: 'MensajesMainView',
    component: MensajesMainView,
    meta: {
      requiresAuth: true
    }
  },

  /**********************************************
   * PLANTILLAS
   **********************************************/
  {
    path: '/plantillas-contratos',
    name: 'PlantillasContratosMainView',
    component: PlantillasContratosMainView,
    meta: {
      requiresAuth: true
    }
  },

  /**********************************************
   * BITACORAS
   **********************************************/
  {
    path: '/historial',
    name: 'Historial',
    component: HistoryView,
    meta: { requiresAuth: true }
  },

  /**********************************************
   * PANEL DE CONTROL
   **********************************************/
  {
    path: '/ayuda/videos',
    name: 'VideosAyuda',
    component: VideosAyuda,
    meta: {
      requiresAuth: true
    }
  },

  /**********************************************
   * OPERACIÓN DEL SISTEMA
   **********************************************/
  {
    path: '/empresas',
    name: 'EmpresasMainView',
    component: EmpresasMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/inversionistas',
    name: 'InversionistasMainView',
    component: InversionistasMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/propiedades',
    name: 'PropiedadesMainView',
    component: PropiedadesMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pagos',
    name: 'RentasMainView',
    component: RentasMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/facturas',
    name: 'FacturasMainView',
    component: FacturasMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/historial-pagos',
    name: 'HistorialPagosMainView',
    component: HistorialPagosMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pagos-sin-comprobante',
    name: 'PagosSinComprobanteMainView',
    component: PagosSinComprobanteMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/documentos',
    name: 'DocumentosMainView',
    component: DocumentosMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/documentos-empresas',
    name: 'DocumentosEmpresaMainView',
    component: DocumentosEmpresaMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/compare-invoices',
    name: 'CompareInvoicesMainView',
    component: CompareInvoicesMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/comprobantes',
    name: 'ComprobantesMainView',
    component: ComprobantesMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contratos/',
    name: 'contratos',
    component: ContratosMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contratos/:proyecto',
    name: 'contratos-proyecto',
    component: ContratosProyectoView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contratos/:proyecto/departamento/:departamento',
    name: 'contratos-proyecto-departamento',
    component: ContratosProyectoDepartamentoView,
    meta: {
      requiresAuth: true
    }
  },
  /*********************************************
   * REPORTES
   **********************************************/
  {
    path: '/reportes/estado-cuenta',
    name: 'EstadoCuentaInversionistaMainView',
    component: EstadoCuentaInversionistaMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reportes/directorio-inversionistas',
    name: 'DirectorioInversionistasMainView',
    component: DirectorioInversionistasMainView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reportes/concentrado-general',
    name: 'ConcentradoGeneralMainView',
    component: ConcentradoGeneralMainView,
    meta: {
      requiresAuth: true
    }
  },
  /**********************************************
   * ACCESO A INVERSIONISTAS
   **********************************************/
  {
    path: '/inversionistas/datos-contacto',
    name: 'DatosContactoInversionistaView',
    component: DatosContactoInversionistaView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/inversionistas/departamentos',
    name: 'DepartamentosInversionistaView',
    component: DepartamentosInversionistaView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/inversionistas/contratos',
    name: 'ContratosInversionistasView',
    component: ContratosInversionistasView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/inversionistas/facturas',
    name: 'FacturasInversionistasView',
    component: FacturasInversionistasView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/inversionistas/documentos',
    name: 'DocumentosInversionistasView',
    component: DocumentosInversionistasView,
    meta: {
      requiresAuth: true
    }
  },

  /**
   * Si la ruta no hace match con ninguna, entonces muestra un error 404.
   *
   * Nota: Siempre debe ser la última ruta.
   */
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/Errors/NotFound.vue')
  }
]

// console.log(JSON.stringify(routes, null, 4))

export { routes, usersAndPermissionsViews, paisesEstadosAndMunicipiosViews }
