import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
import SecureLs from 'secure-ls'
import { vuexfireMutations } from 'vuexfire'

import authModule from '@/store/modules/AuthModule'
import roleModule from '@/store/modules/RoleModule'
import permissionModule from '@/store/modules/PermissionModule'
import userModule from '@/store/modules/UserModule'
import UIModule from '@/store/modules/UIModule'
import historyModule from '@/store/modules/HistoryModule'
import archivoModule from '@/store/modules/ArchivoModule'

//
// Módulos de Administración del Sistema
import periodoModule from '@/store/modules/administracion/PeriodoModule'
import inpcModule from '@/store/modules/administracion/INPCModule'
import notificationsModule from '@/store/modules/NotificationsModule'
import notificacionesModule from '@/store/modules/NotificacionesModule'
import mensajesModule from '@/store/modules/MensajesModule'

//
// Módulos del Sistema de Reglas
import reglasModule from '@/store/modules/se/SeReglaModule'
import reglasSatModule from '@/store/modules/administracion/ReglasSatModule'
import estructuraCfdiModule from '@/store/modules/se/SeEstructuraCfdiModule'
import reglasEstructuraCfdiModule from '@/store/modules/se/SeReglaEstructuraCfdiModule'
import informacionCatalogosModule from '@/store/modules/administracion/InformacionCatalogosModule'

//
// Módulos de Catálogos del Sistema
import paisModule from '@/store/modules/catalogos/PaisModule'
import estadoModule from '@/store/modules/catalogos/EstadoModule'
import municipioModule from '@/store/modules/catalogos/MunicipioModule'
import formaDePagoModule from '@/store/modules/catalogos/FormaDePagoModule'
import condominioModule from '@/store/modules/catalogos/CondominioModule'
import subcondominioModule from '@/store/modules/catalogos/SubcondominioModule'
import edificioModule from '@/store/modules/catalogos/EdificioModule'
import departamentoModule from '@/store/modules/catalogos/DepartamentoModule'
import regimenModule from '@/store/modules/catalogos/RegimenModule'
import usoCfdiModule from '@/store/modules/catalogos/UsoCfdiModule'
//
// Módulos de Catálogos del SAT
import versionCfdiModule from '@/store/modules/sat/VersionCfdiModule'
//
// Módulos de Operatividad del Sistema
import inversionistaModule from '@/store/modules/operaciones/InversionistaModule'
import propiedadModule from '@/store/modules/operaciones/PropiedadModule'
import rentaModule from '@/store/modules/operaciones/RentaModule'
import facturaModule from '@/store/modules/operaciones/FacturaModule'
import listadoModule from '@/store/modules/operaciones/ListadoModule'
import historialPagosModule from '@/store/modules/operaciones/HistorialPagosModule'
import pagosSinComprobanteModule from '@/store/modules/operaciones/PagosSinComprobanteModule'
import documentosModule from '@/store/modules/operaciones/DocumentosModule'
import documentosEmpresaModule from '@/store/modules/operaciones/DocumentosEmpresaModule'
import empresaModule from '@/store/modules/operaciones/EmpresaModule'
import compareInvoicesModule from '@/store/modules/operaciones/CompareInvoicesModule'
import plantillasContratosModule from '@/store/modules/PlantillasContratosModule'
import comprobantesModule from '@/store/modules/operaciones/ComprobantesModule'
import contratosModule from '@/store/modules/operaciones/ContratosModule'
//
// Módulos de Información para Tableros
import dashboardAdministradorModule from '@/store/modules/dashboards/DashboardAdministradorModule'
import dashboardInversionistaModule from '@/store/modules/dashboards/DashboardInversionistaModule'
// Módulos de Reportes
import estadoCuentaInversionistaModule from '@/store/modules/reportes/EstadoCuentaInversionistaModule'
import directorioInversionistasModule from '@/store//modules/reportes/DirectorioInversionistasModule'
import concentradoGeneralModule from '@/store/modules/reportes/ConcentradoGeneralModule'
//
// Módulos de acceso de Inversionistas
import datosContactoInversionistaModule from '@/store/modules/inversionistas/DatosContactoModule'
import departamentosInversionistaModule from '@/store/modules/inversionistas/DepartamentosInversionistaModule'
import contratosInversionistasModule from '@/store/modules/inversionistas/ContratosInversionistasModule'
import facturasInversionistasModule from '@/store/modules/inversionistas/FacturasInversionistasModule'
import institucionBancariaModule from '@/store/modules/inversionistas/InstitucionBancariaModule'
import documentosInversionistaModule from '@/store/modules/inversionistas/DocumentosInversionistaModule'

const ls = new SecureLs({ isCompression: false })

Vue.use(Vuex)

const persistedstate = createPersistedstate({
  //
  // Pertiste en local storage los siguientes estados
  paths: [
    'userModule.authUser',
    'userModule.cloneAuthUser',
    'userModule.viewAsUser'
    //
    //
  ]
})

//
// El almacenamiento de información por razones de seguridad
// es encriptado, esto se hace automáticamente para los estados
// que se han establecido en el path perseverar.
if (process.env.NODE_ENV === 'production') {
  persistedstate.storage = {
    getItem: key => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: key => ls.remove(key)
  }
}

export default new Vuex.Store({
  state: {},
  mutations: {
    ...vuexfireMutations
  },
  actions: {},
  modules: {
    UIModule,
    authModule,
    // Administración
    userModule,
    roleModule,
    permissionModule,
    periodoModule,
    inpcModule,
    historyModule,
    archivoModule,
    informacionCatalogosModule,
    notificationsModule,
    notificacionesModule,
    mensajesModule,
    // Sistema de Reglas
    reglasModule,
    reglasSatModule,
    estructuraCfdiModule,
    reglasEstructuraCfdiModule,
    // Catalogos
    paisModule,
    estadoModule,
    municipioModule,
    formaDePagoModule,
    condominioModule,
    subcondominioModule,
    edificioModule,
    departamentoModule,
    regimenModule,
    usoCfdiModule,
    // Catálogos SAT
    versionCfdiModule,
    // Operaciones
    inversionistaModule,
    propiedadModule,
    rentaModule,
    facturaModule,
    listadoModule,
    historialPagosModule,
    pagosSinComprobanteModule,
    documentosModule,
    documentosEmpresaModule,
    plantillasContratosModule,
    compareInvoicesModule,
    empresaModule,
    comprobantesModule,
    contratosModule,
    // Tableros
    dashboardAdministradorModule,
    dashboardInversionistaModule,
    // Reportes
    estadoCuentaInversionistaModule,
    directorioInversionistasModule,
    concentradoGeneralModule,
    // Inversionistas
    datosContactoInversionistaModule,
    departamentosInversionistaModule,
    contratosInversionistasModule,
    facturasInversionistasModule,
    institucionBancariaModule,
    documentosInversionistaModule
  },
  plugins: [persistedstate]
})
