<template>
  <div>
    <div class="row">
      <div class="col-xl-3 col-xxl-3">
        <div class="card card-header-actions mb-3 cursor-pointer" role="button" @click="modal_vencimiento = true">
          <div class="card-header">
            Contratos por vencer: {{ numContratosPorVencer }}
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3">
        <div class="card card-header-actions mb-3 cursor-pointer" role="button" @click="modal_pagadas = true">
          <div class="card-header">
            Propiedades pagadas: {{ propiedades.pagadas }}<br>
            Importe pagado: {{ $formatNumber(propiedades.importe_pagado) }}
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3">
        <div class="card card-header-actions mb-3 cursor-pointer" role="button" @click="modal_pendientes = true">
          <div class="card-header">
            Propiedades pendientes: {{ propiedades.pendientes }}<br>
            Importe pendiente: {{ $formatNumber(propiedades.importe_pendiente) }}
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3">
        <div class="card card-header-actions mb-3" @click="modal_actualizacion = numContratosPorActualizar > 0">
          <div v-if="numContratosPorActualizar > 0" class="card-header text-danger">
            Importes por actualizar: {{ numContratosPorActualizar }}
          </div>
          <div v-else class="card-header">
            Importes por actualizar: {{ numContratosPorActualizar }}
          </div>
        </div>
      </div>
    </div>

    <!-- Concentrado general -->
    <div class="row">
      <div class="col-xxl-6">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            Resumen general de cuentas por pagar/pagadas
          </div>
          <div class="card-body">
            <b-table
              id="resumenGeneralTable"
              v-if="getAvailableList"
              :fields="resumen_fields"
              :items="resumen_table"
              striped
              bordered
              responsive
              hover
              stacked="lg"
              show-empty
              small
            >
              <template #cell(subcondominio)="row">
                <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
                {{ row.value }}
                </span>
              </template>
              <template #cell(inversionistas)="row">
                <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
                {{ row.value }}
                </span>
              </template>
              <template #cell(importe_por_pagar)="row">
                <span class="text-sm text-right w-100" style="float: right;" :class="{ 'text-muted': row.item.is_deleted }">
                {{ $formatNumber(row.value) }}
                </span>
              </template>
              <template #cell(importe_pagado)="row">
                <span class="text-sm text-sm-right w-100" style="float: right;" :class="{ 'text-muted': row.item.is_deleted }">
                {{ $formatNumber(row.value) }}
                </span>
              </template>
            </b-table>
            <loading v-else></loading>
          </div>
        </div>
      </div>
      <div class="col-xxl-6">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            Cuentas por pagar/pagadas (gráfico)
          </div>
          <div class="card-body">
            <Bar
              :chart-options="chartPagosOptions"
              :chart-data="chartConcentradoData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"
              v-if="getAvailableList"
            />
            <loading v-else></loading>
          </div>
        </div>
      </div>
    </div>

    <!-- Gráficos -->
    <div class="row">
      <div class="col-xxl-6">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            Histórico mensual de pagos y retenciones (Con factura)
          </div>
          <div class="card-body">
            <Bar
              :chart-options="chartPagosOptions"
              :chart-data="chartPagosCampusData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"
              v-if="getAvailableList"
            />
            <loading v-else></loading>
          </div>
        </div>
      </div>
      <div class="col-xxl-6">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            Histórico mensual de pagos (Sin factura)
          </div>
          <div class="card-body">
            <Bar
              :chart-options="chartPagosOptions"
              :chart-data="chartPagosProyectosData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"
              v-if="getAvailableList"
            />
            <loading v-else></loading>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-3">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            INPC Año actual, proyección
          </div>
          <div class="card-body">
            <LineChart
              :chart-options="chartProyeccionesOptions"
              :chart-data="chartINPCProyeccionData"
              :chart-id="chartActualId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="50"
              :height="50"
              v-if="getAvailableList"
            />
            <loading v-else></loading>
          </div>
        </div>
      </div>
      <div class="col-xxl-3">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            Proyección Financiera (Campus)
          </div>
          <div class="card-body">
            <LineChart
              :chart-options="chartProyeccionesOptions"
              :chart-data="chartProyeccionData"
              :chart-id="chartProyeccionId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="50"
              :height="50"
              v-if="getAvailableList"
            />
            <loading v-else></loading>
          </div>
        </div>
      </div>
      <div class="col-xxl-3">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            Proyección Financiera (Otros)
          </div>
          <div class="card-body">
            <LineChart
              :chart-options="chartProyeccionesOptions"
              :chart-data="chartProyeccionOtrosData"
              :chart-id="chartProyeccionOtrosId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="50"
              :height="50"
              v-if="getAvailableList"
            />
            <loading v-else></loading>
          </div>
        </div>
      </div>
      <div class="col-xxl-3">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            Propiedades pagadas/pendientes
          </div>
          <div class="card-body">
            <Pie
              :chart-options="pieOptions"
              :chart-data="piePropiedadesData"
              chart-id="pieId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="50"
              :height="50"
              v-if="getAvailableList"
            />
            <loading v-else></loading>
          </div>
        </div>
      </div>
    </div>

    <!-- Total y listado de contratos pagados -->
    <modal-contratos-pagados
      :modal="modal_pagadas"
      :periodo="actual"
      @closed="modal_pagadas=false"/>

    <!-- Total y listado de contratos pendientes -->
    <modal-contratos-pendientes
      :modal="modal_pendientes"
      :periodo="actual"
      @closed="modal_pendientes=false"/>

    <!-- Total y listado de contratos por vencer en un periódo de 12 meses -->
    <modal-contratos-por-vencer
      :modal="modal_vencimiento"
      :periodo="actual"
      @closed="modal_vencimiento=false"
      @retrieveData="(count) => this.numContratosPorVencer = count"/>

    <!-- Total y listado de contratos con importe por renovar -->
    <modal-contratos-por-renovar
      :modal="modal_actualizacion"
      :periodo="actual"
      @closed="modal_actualizacion=false"
      @retrieveData="(count) => this.numContratosPorActualizar = count"/>
  </div>
</template>

<script>
// import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from 'vue-feather-icons'
import { Bar, Pie, Line as LineChart } from 'vue-chartjs/legacy'
import { mapGetters } from 'vuex'
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LineElement, LinearScale, Title, Tooltip, PointElement } from 'chart.js'
import ModalContratosPorVencer from '../../components/Dashboards/DashboardAdmin/ModalContratosPorVencer.vue'
import ModalContratosPendientes from '../../components/Dashboards/DashboardAdmin/ModalContratosPendientes.vue'
import ModalContratosPagados from '../../components/Dashboards/DashboardAdmin/ModalContratosPagados.vue'
import ModalContratosPorRenovar from '../../components/Dashboards/DashboardAdmin/ModalContratosPorRenovar.vue'
import service from '@/api/service'

const apiName = '/api/proyecciones-financieras'
const STORE_MODULE = 'dashboardAdministradorModule'
const PERIODO_MODULE = 'periodoModule'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, PointElement, LineElement)

export default {
  name: 'DashboardAdmin',

  components: {
    ModalContratosPorRenovar,
    ModalContratosPagados,
    ModalContratosPendientes,
    ModalContratosPorVencer,
    Bar,
    Pie,
    LineChart
    // ArrowLeftCircleIcon,
    // ArrowRightCircleIcon
  },

  created () {
    this.init()
  },

  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 184
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      anterior: null,
      actual: null,
      siguiente: null,

      modal_vencimiento: false,
      modal_pagadas: false,
      modal_pendientes: false,
      modal_actualizacion: false,

      propiedades: {
        total: 0,
        pagadas: 0,
        pendientes: 0,
        actualizar: 0,
        importe_pagado: 0,
        importe_pendiente: 0
      },

      numContratosPorVencer: 0,
      numContratosPorActualizar: 0,

      resumen_fields: [
        { key: 'subcondominio', label: 'Condominio' },
        { key: 'inversionistas', label: 'Inversionistas' },
        { key: 'importe_por_pagar', label: 'Por pagar' },
        { key: 'importe_pagado', label: 'Pagado' }
      ],
      resumen_table: [],
      fields: [
        { key: 'cfdi_comprobante.fecha', label: 'Fecha Comprobante' },
        { key: 'cfdi_comprobante.complemento.uuid', label: 'Folio Fiscal' },
        { key: 'cfdi_comprobante.folio', label: 'Folio Interno' },
        { key: 'cfdi_comprobante.version', label: 'Versión' },
        { key: 'importe', label: 'Importe' },
        { key: 'status', label: 'Status' },
        { key: 'periodo.descriptor', label: 'Período' },
        { key: 'departamentos', label: 'Departamentos' }
      ],
      piePagosData: {
        labels: ['Pagado', 'Pendiente'],
        datasets: [
          {
            data: [0, 0],
            backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
            borderColor: ['rgba(54, 162, 235)', 'rgba(255, 99, 132)'],
            borderWidth: 1
          }
        ]
      },
      piePropiedadesData: {
        labels: ['Pagadas', 'Pendientes'],
        datasets: [
          {
            data: [0, 0, 0, 0, 0],
            backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(255, 205, 86, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(201, 203, 207, 0.2)'],
            borderColor: ['rgba(54, 162, 235)', 'rgba(75, 192, 192)', 'rgba(255, 205, 86)', 'rgba(255, 99, 132)', 'rgba(201, 203, 207)'],
            borderWidth: 1
          }
        ]
      },
      pieOptions: {
        responsive: true
      },

      chartConcentradoData: {
        labels: ['Subcondominios'],
        datasets: [{
          label: 'Por pagar',
          data: [0],
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1
        }, {
          label: 'Pagado',
          data: [0],
          backgroundColor: 'rgba(255, 205, 86, 0.2)',
          borderColor: 'rgba(255, 205, 86)',
          borderWidth: 1
        }]
      },
      chartData: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'Propiedades programadas para pago',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1
        }]
      },
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        }
      },
      chartPagosCampusData: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'Pagos',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1
        }, {
          label: 'IVA',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(192, 75, 192, 0.2)',
          borderColor: 'rgba(192, 75, 192)',
          borderWidth: 1
        }, {
          label: 'Retenciones ISR (001)',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192)',
          borderWidth: 1
        }, {
          label: 'Retenciones IVA (002)',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(255, 205, 86, 0.2)',
          borderColor: 'rgba(255, 205, 86)',
          borderWidth: 1
        }]
      },
      chartPagosProyectosData: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'Pagos',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1
        }]
      },
      chartPagosOptions: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
            }
          }
        }
      },
      chartProyeccionesOptions: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
            }
          }
        },
        scales: {
          y: {
            beginAtZero: false
          }
        }
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
      chartActualId: 'chart-actual-id',
      chartProyeccionId: 'chart-proyeccion-id',
      chartProyeccionOtrosId: 'chart-proyeccion-otros-id',
      chartINPCProyeccionData: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'INPC',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1
        }, {
          label: 'Pronóstico',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192)',
          borderWidth: 1
        }, {
          label: 'Optimista',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(255, 205, 86, 0.2)',
          borderColor: 'rgba(255, 205, 86)',
          borderWidth: 1
        }, {
          label: 'Pesimista',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132)',
          borderWidth: 1
        }]
      },
      chartProyeccionData: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'Proyeccion',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1
        }, {
          label: 'Optimista',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(255, 205, 86, 0.2)',
          borderColor: 'rgba(255, 205, 86)',
          borderWidth: 1
        }, {
          label: 'Pesimista',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132)',
          borderWidth: 1
        }]
      },
      chartProyeccionOtrosData: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'Proyeccion',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1
        }]
      }
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceData',
      'getAvailableList',
      'isThereData',
      'getResource'
    ]),
    exists_periodo_anterior () {
      return Boolean(this.anterior)
    },
    exists_periodo_siguiente () {
      return Boolean(this.siguiente)
    }
  },

  watch: {
    actual: {
      immediate: true,
      handler (value) {
        if (value) {
          this.concentrado(value)
          this.operaciones(value)
          this.retenciones(value)
          this.pagos(value)
          this.resumen(value)
          this.grafico_meses(value)
          this.grafico_pastel(value)
        }
      }
    }
  },

  methods: {
    async init () {
      // Inicializamos secciones
      this.periodoActual()
      this.getProyeccion()
      this.getProyeccionFinanciera()
      this.getProyeccionFinancieraOtros()
    },

    async periodoActual () {
      // Obtenemos el período que corresponda al mes y año actual
      const result1 = await this.$store.dispatch(`${PERIODO_MODULE}/getPeriodoActual`)
      if (result1.error) {
        const { error, message } = result1

        this.$notify({ error, message }, 'Rentas')
        return
      }
      this.anterior = result1.data.anterior
      this.actual = result1.data.periodo
      this.siguiente = result1.data.siguiente
    },

    async periodoAnterior () {
      // Obtenemos el período que corresponda al mes y año actual
      const result1 = await this.$store.dispatch(`${PERIODO_MODULE}/getPeriodoAnterior`, this.actual.id)
      if (result1.error) {
        const { error, message } = result1

        this.$notify({ error, message }, 'Rentas')
        return
      }
      this.anterior = result1.data.anterior
      this.actual = result1.data.periodo
      this.siguiente = result1.data.siguiente
    },

    async periodoSiguiente () {
      // Obtenemos el período que corresponda al mes y año actual
      const result1 = await this.$store.dispatch(`${PERIODO_MODULE}/getPeriodoSiguiente`, this.actual.id)
      if (result1.error) {
        const { error, message } = result1

        this.$notify({ error, message }, 'Rentas')
        return
      }
      this.anterior = result1.data.anterior
      this.actual = result1.data.periodo
      this.siguiente = result1.data.siguiente
    },

    async concentrado (periodo) {
      const result = await this.$store.dispatch(`${STORE_MODULE}/getResumenGeneral`, periodo.id)
      if (result.error) {
        const {
          error,
          message
        } = result
        this.$notify({
          error,
          message
        }, 'Dashboard')
      }
      this.resumen_table = result.data.concentrado
      this.chartConcentradoData.labels = result.data.concentrado.map((data) => data.subcondominio)
      this.chartConcentradoData.datasets[0].data = result.data.concentrado.map((data) => data.importe_por_pagar)
      this.chartConcentradoData.datasets[1].data = result.data.concentrado.map((data) => data.importe_pagado)
    },

    async operaciones (periodo) {
      const result2 = await this.$store.dispatch(`${STORE_MODULE}/getOperaciones`, periodo.id)
      if (result2.error) {
        const {
          error,
          message
        } = result2
        this.$notify({
          error,
          message
        }, 'Rentas')
      }
    },

    async retenciones (periodo) {
      const result2 = await this.$store.dispatch(`${STORE_MODULE}/getPagosRetenciones`, periodo.id)
      if (result2.error) {
        const {
          error,
          message
        } = result2
        this.$notify({
          error,
          message
        }, 'Rentas')
      }
      // El primer gráfico consiste únicamente de los pagos de Campus
      // Obtenemos el RFC de la empresa 1 (Campus)
      const rfc = result2.data.empresas[0].rfc
      this.chartPagosCampusData.datasets[0].data = result2.data.importes[rfc].pagos
      this.chartPagosCampusData.datasets[1].data = result2.data.importes[rfc].traslados
      this.chartPagosCampusData.datasets[2].data = result2.data.importes[rfc].retenciones_isr
      this.chartPagosCampusData.datasets[3].data = result2.data.importes[rfc].retenciones_iva
    },

    async pagos (periodo) {
      const result2 = await this.$store.dispatch(`${STORE_MODULE}/getPagos`, periodo.id)
      if (result2.error) {
        const {
          error,
          message
        } = result2
        this.$notify({
          error,
          message
        }, 'Rentas')
      }
      // El segundo gráfico concentra los pagos realizados en los otros condominios
      /* datasets: [{
        label: 'Pagos',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 1
      }] */
      const backgroundColors = ['rgba(54, 162, 235, 0.2)', 'rgba(192, 75, 192, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(255, 205, 86, 0.2)']
      const borderColors = ['rgb(54, 162, 235)', 'rgba(192, 75, 192)', 'rgba(75, 192, 192)', 'rgba(255, 205, 86)']
      const empresas = result2.data.empresas
      this.chartPagosProyectosData.datasets = empresas.map((empresa) => {
        return {
          label: empresa.razon_social,
          data: result2.data.importes[empresa.rfc].pagos,
          backgroundColor: backgroundColors[empresa.id - 1],
          borderColor: borderColors[empresa.id - 1],
          borderWidth: 1
        }
      })
    },

    async resumen (periodo) {
      const result3 = await this.$store.dispatch(`${STORE_MODULE}/getResumen`, periodo.id)
      if (result3.error) {
        const { error, message } = result3
        this.$notify({ error, message }, 'Rentas')
      }
      this.propiedades = result3.data.propiedades
    },

    async grafico_meses (periodo) {
      const result3 = await this.$store.dispatch(`${STORE_MODULE}/getGraficoMeses`, periodo.id)
      if (result3.error) {
        const { error, message } = result3
        this.$notify({ error, message }, 'Rentas')
      }
      this.chartData.datasets[0].data = result3.data.programados
    },

    async grafico_pastel (periodo) {
      const result3 = await this.$store.dispatch(`${STORE_MODULE}/getGraficoPastel`, periodo.id)
      if (result3.error) {
        const { error, message } = result3
        this.$notify({ error, message }, 'Rentas')
      }
      // labels: ['Programados', 'Pagados', 'Rechazados', 'Cancelados', 'Pendientes'],
      // data: [0, 0, 0, 0, 0]
      this.piePropiedadesData.datasets[0].data = [
        result3.data.propiedades.pagadas,
        result3.data.propiedades.pendientes
      ]
      this.piePagosData.datasets[0].data = [
        result3.data.pagos.pagado,
        result3.data.pagos.pendiente
      ]
    },

    async getProyeccion () {
      const result = await service.get(`${apiName}/inpc-proyeccion`)
      this.inpc_proyeccion = result.data
      const chartData = result.data.data.data.map((row) => row[1])
      const chartData2 = result.data.data.forecast.map((row) => row[1])
      const chartData3 = result.data.data.forecast.map((row) => row[2])
      const chartData4 = result.data.data.forecast.map((row) => row[3])
      this.chartINPCProyeccionData.datasets[0].data = chartData
      this.chartINPCProyeccionData.datasets[1].data = chartData2
      this.chartINPCProyeccionData.datasets[3].data = chartData4
      this.chartINPCProyeccionData.datasets[2].data = chartData3
    },
    async getProyeccionFinanciera () {
      const result = await service.get(`${apiName}/proyeccion-financiera`)
      const chartData1 = result.data.data.map((row) => row[1])
      const chartData2 = result.data.data.map((row) => row[2])
      const chartData3 = result.data.data.map((row) => row[3])
      this.chartProyeccionData.datasets[0].data = chartData1
      this.chartProyeccionData.datasets[1].data = chartData2
      this.chartProyeccionData.datasets[2].data = chartData3
    },
    async getProyeccionFinancieraOtros () {
      const result = await service.get(`${apiName}/proyeccion-financiera-otros`)
      const empresas = result.data.data.empresas
      const proyecciones = result.data.data.proyecciones
      const backgroundColors = ['rgba(54, 162, 235, 0.2)', 'rgba(192, 75, 192, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(255, 205, 86, 0.2)']
      const borderColors = ['rgb(54, 162, 235)', 'rgba(192, 75, 192)', 'rgba(75, 192, 192)', 'rgba(255, 205, 86)']
      this.chartProyeccionOtrosData.datasets = empresas.map((empresa) => {
        return {
          label: empresa.razon_social,
          data: proyecciones[empresa.rfc].map((data) => data[1]),
          backgroundColor: backgroundColors[empresa.id - 2],
          borderColor: borderColors[empresa.id - 2],
          borderWidth: 1
        }
      })
    }
  }
}
</script>
