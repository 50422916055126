<template>
  <b-modal
    v-model="modal_pagadas"
    centered
    size="lg"
    scrollable
    no-close-on-backdrop
    dialog-class="x-modal-md"
  >
    <template #modal-title>
      <span class="text-primary">Lista de contratos pagados</span>
    </template>
    <b-table
      id="contratosPagadosTable"
      v-if="!loading"
      :fields="contratosPagadosFields"
      :items="contratosPagadosData"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <template #cell(num_catastral)="row">
          <span class="text-sm text-center w-100">
            {{ row.item.num_catastral }}
          </span>
      </template>

      <template #cell(cuenta_predial)="row">
          <span class="text-sm text-center w-100">
            {{ row.item.cuenta_predial }}
          </span>
      </template>

      <template #cell(inicio_contrato)="row">
          <span class="text-sm text-center w-100">
            {{ row.item.inicio_contrato }}
          </span>
      </template>

      <template #cell(termino_contrato)="row">
          <span class="text-sm text-center w-100">
            {{ row.item.termino_contrato }}
          </span>
      </template>

      <template #cell(inversionista.identificacion)="row">
          <span class="text-sm text-center w-100">
            {{ row.item.inversionista.identificacion }}
          </span>
      </template>

      <template #cell(inversionista.rfc)="row">
          <span class="text-sm text-center w-100">
            {{ row.item.inversionista.rfc }}
          </span>
      </template>
    </b-table>
    <loading v-else></loading>
    <template #modal-footer>
      <!-- Enviar para crear o actualizar -->
      <div class="w-100">
      </div>
    </template>
  </b-modal>
</template>

<script>
const STORE_MODULE = 'dashboardAdministradorModule'
export default {
  name: 'ModalContratosPagados',

  props: {
    modal: {
      required: true
    },
    periodo: {
      required: true
    }
  },

  data () {
    return {
      contratosPagadosData: [],
      contratosPagadosFields: [
        { key: 'num_catastral', label: 'No. Catastral' },
        { key: 'cuenta_predial', label: 'No. Cuenta Predial' },
        { key: 'inicio_contrato', label: 'Fecha inicio' },
        { key: 'termino_contrato', label: 'Fecha término' },
        { key: 'inversionista.identificacion', label: 'Inversionista' },
        { key: 'inversionista.rfc', label: 'RFC' }
      ],
      modal_pagadas: false,
      loading: false
    }
  },

  watch: {
    periodo: {
      immediate: true,
      handler (value) {
        if (value) {
          this.contratos_pagados(value)
        }
      }
    },
    modal: {
      immediate: true,
      handler (value) {
        if (value) {
          this.modal_vencimiento = value
        }
      }
    },
    modal_vencimiento: {
      immediate: true,
      handler (value) {
        if (!value) {
          this.$emit('closed')
        }
      }
    }
  },

  methods: {
    async contratos_pagados (periodo) {
      this.loading = true
      const result = await this.$store.dispatch(`${STORE_MODULE}/getContratosPagados`, { periodo_id: periodo.id, meses: 12 })
      this.loading = false
      if (result.error) {
        const { error, message } = result
        this.$notify({ error, message }, 'Contratos')
      }
      this.contratosPagadosData = result.data.contratos
    }
  }
}
</script>

<style scoped>

</style>
