<template>
  <div>
    <div class="sidenav-menu-heading">Catálogos</div>

    <sidebar-link
      :requiresAuthorizations="['indice version-cfdi']"
      :to="{ name: 'SatInfoCatalogosMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="check-square"></i>
      </div>
      SAT
    </sidebar-link>

    <sidebar-link
      :requiresAuthorizations="['indice formas de pago']"
      :to="{ name: 'FormasDePagoView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="dollar-sign"></i>
      </div>
      Formas de pago
    </sidebar-link>

    <sidebar-link
      :requiresAuthorizations="['indice empresas']"
      :to="{ name: 'EmpresasMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="briefcase"></i>
      </div>
      Empresas
    </sidebar-link>

    <a
      class="nav-link my-cursor"
      :class="activeAccordeon === 'proyectosAndDesarrollos' ? '' : 'collapsed'"
      data-toggle="collapse"
      data-target="#collapseDashboards"
      :aria-expanded="activeAccordeon === 'proyectosAndDesarrollos' ? true : false"
      aria-controls="collapseDashboards"
      >
      <div class="nav-link-icon"><i data-feather="home"></i></div>
      Desarrollos
      <div class="sidenav-collapse-arrow">
        <i class="fas fa-angle-down"></i>
      </div>
    </a>
    <div
      :class="activeAccordeon === 'proyectosAndDesarrollos' ? 'collapse show' : 'collapse'"
      id="collapseDashboards"
      data-parent="#accordionSidenav"
    >
      <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPages">
        <sidebar-link
          :requiresAuthorizations="['indice condominios']"
          :to="{ name: 'Proyectos' }"
        >
          Proyectos
        </sidebar-link>
        <sidebar-link
          :requiresAuthorizations="['indice subcondominios']"
          :to="{ name: 'Etapas' }"
        >
          Etapas
        </sidebar-link>
        <sidebar-link
          :requiresAuthorizations="['indice edificios']"
          :to="{ name: 'Edificios' }"
        >
          Edificios
        </sidebar-link>
      </nav>
    </div>

    <sidebar-link
      :requiresAuthorizations="['indice departamentos']"
      :to="{ name: 'Departamentos' }"
    >
      <div class="nav-link-icon">
        <i data-feather="columns"></i>
      </div>
      Departamentos
    </sidebar-link>

    <sidebar-link
      :requiresAuthorizations="['indice inversionistas']"
      :to="{ name: 'InversionistasMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="users"></i>
      </div>
      Inversionistas
    </sidebar-link>
  </div>
</template>

<script>
import SidebarLink from './SidebarLink'

export default {
  name: 'SidebarSectionCatalogos',

  props: {
    activeAccordeon: {
      required: true
    }
  },

  components: {
    SidebarLink
  }
}
</script>

<style scoped>

</style>
