<template>
  <div>
    <div class="sidenav-menu-heading">Reportes</div>

    <!-- Sidenav Accordion (Retenciones y propiedas pagadas)-->
    <a
      class="nav-link my-cursor"
      :class="activeAccordeon === 'listados' ? '' : 'collapsed'"
      data-toggle="collapse"
      data-target="#collapseListados"
      :aria-expanded="activeAccordeon === 'listados' ? true : false"
      aria-controls="collapseListados"
    >
      <div class="nav-link-icon"><i data-feather="layers"></i></div>
      Listados
      <div class="sidenav-collapse-arrow">
        <i class="fas fa-angle-down"></i>
      </div>
    </a>
    <div
      :class="activeAccordeon === 'listados' ? 'collapse show' : 'collapse'"
      id="collapseListados"
      data-parent="#accordionSidenav"
    >
      <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPages">
        <sidebar-link
          :requiresAuthorizations="['listado retenciones']"
          :to="{ name: 'ListadoRetenciones', params: { tabActive: 'retenciones' } }"
        >
          Retenciones
        </sidebar-link>
        <sidebar-link
          :requiresAuthorizations="['listado propiedades pagadas']"
          :to="{ name: 'ListadoPropiedades', params: { tabActive: 'propiedades' } }"
        >
          Propiedades pagadas
        </sidebar-link>
      </nav>
    </div>

    <sidebar-link
      :requiresAuthorizations="['reporte estados cuenta']"
      :to="{ name: 'EstadoCuentaInversionistaMainView' }"
    >
      <div class="nav-link-icon">
        <i data-feather="activity"></i>
      </div>
      Estado de Cuenta por Inversionista
    </sidebar-link>

    <sidebar-link
      :requires-authorizations="['reporte directorio']"
      :to="{ name: 'DirectorioInversionistasMainView' }"
      >
      <div class="nav-link-icon">
        <i data-feather="activity"></i>
      </div>
      Directorio de Inversionistas
    </sidebar-link>

    <sidebar-link
      :requires-authorizations="['reporte concentrado general']"
      :to="{ name: 'ConcentradoGeneralMainView' }"
    >
      <div class="nav-link-icon">
        <i data-feather="activity"></i>
      </div>
      Cuentas por Pagar
    </sidebar-link>
    <!-- Saldos -->
    <!--a
      class="nav-link my-cursor"
      :class="activeAccordeon === 'saldos' ? '' : 'collapsed'"
      data-toggle="collapse"
      data-target="#collapseSaldos"
      :aria-expanded="activeAccordeon === 'saldos' ? true : false"
      aria-controls="collapseSaldos"
    >
      <div class="nav-link-icon"><i data-feather="dollar-sign"></i></div>
      Saldos
      <div class="sidenav-collapse-arrow">
        <i class="fas fa-angle-down"></i>
      </div>
    </a-->
    <!--div
      :class="activeAccordeon === 'saldos' ? 'collapse show' : 'collapse'"
      id="collapseSaldos"
      data-parent="#accordionSidenav"
    >
      <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesSaldos">
        <sidebar-link
          :requiresAuthorizations="['consultar saldos unidades administrativas']"
          :to="{ name: 'SaldosPorUnidad' }"
        >
          Por unidades administrativas
        </sidebar-link>

        <sidebar-link
          :requiresAuthorizations="['consultar saldos partidas']"
          :to="{ name: 'SaldosPorPartida' }"
        >
          Por partidas presupuestales
        </sidebar-link>

        <sidebar-link
          :requiresAuthorizations="['consultar saldos momentos']"
          :to="{ name: 'SaldosPorMomento' }"
        >
          Por momento contable
        </sidebar-link>
      </nav>
    </div-->

    <!-- Reportes -->
    <!--a
      class="nav-link my-cursor"
      :class="activeAccordeon === 'reportes' ? '' : 'collapsed'"
      data-toggle="collapse"
      data-target="#collapseReportes"
      :aria-expanded="activeAccordeon === 'reportes' ? true : false"
      aria-controls="collapseReportes"
    >
      <div class="nav-link-icon"><i data-feather="bar-chart"></i></div>
      Reportes
      <div class="sidenav-collapse-arrow">
        <i class="fas fa-angle-down"></i>
      </div>
    </a-->
    <!--div
      :class="activeAccordeon === 'reportes' ? 'collapse show' : 'collapse'"
      id="collapseReportes"
      data-parent="#accordionSidenav"
    >
      <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesReportes">
        <sidebar-link
          :requiresAuthorizations="['consultar reporte analitico egresos']"
          :to="{ name: 'ReporteAnaliticoPptoEgresos' }"
        >
          Estado del Ejercicio del Ppto. de Egresos
        </sidebar-link>
      </nav>
      <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPagesReportes">
        <sidebar-link
          :requiresAuthorizations="['consultar reporte auxiliar cuentas']"
          :to="{ name: 'ReporteAuxiliarCuentas' }"
        >
          Auxiliar de Cuentas
        </sidebar-link>
      </nav>

    </div-->

    <!-- Importar exportar -->
    <!-- <a class="nav-link" href="#">
      <div class="nav-link-icon"><i data-feather="filter"></i></div>
      Importar/Exportar Catálogos
    </a> -->
  </div>
</template>

<script>
import SidebarLink from './SidebarLink'

export default {
  name: 'SidebarSectionReportes',

  props: {
    activeAccordeon: {
      required: true
    }
  },

  components: {
    SidebarLink
  }
}
</script>

<style scoped>

</style>
