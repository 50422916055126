import Resource from '@/api/Resource'

const apiName = '/api/empresas'
const modName = 'empresaModule'
const resource = new Resource(apiName, modName)

export default {
  namespaced: true,

  state: {
    /**
     * Referencia al recurso obtenido de la API del backend.
     */
    resource: null,

    select: null,

    /**
     * Término ingresado para buscar un componente en la API.
     */
    query: null,

    /**
     * Cuando se obtiene la información de un recurso a actualizar,
     * se almacena en esta variable, se declara como nulo inicialmente
     * para saber si actualmente se está editando o no.
     */
    editingResource: null,

    isLoadingResource: false,

    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false
  },

  getters: {
    /**
     * 'isThereUsers'.
     *
     * Retorna para indicar si el resource del módulo tiene datos
     */
    isThereData: ({ resource }) => Boolean(resource && resource.data),

    isThereSelect: ({ select }) => Boolean(select && select.data),

    isThereSelectedDepto: ({ selectedDepto }) => Boolean(selectedDepto && selectedDepto.data),

    getList: ({ resource }, getters) => (getters.isThereData ? resource.data : []),

    getResource: (state, getters) => (getters.isThereData ? state.resource : {}),

    getSelectData: (state, getters) => (getters.isThereSelect ? state.select.data : []),

    getTotalItems: (state, getters) => (getters.isThereData ? state.resource.data.length : 0),

    /**
     * 'isEditingResource'.
     *
     * Indica si actualmente se está o no editando un recurso.
     */
    isEditingResource: state => Boolean(state.editingResource),

    /**
     * 'getAvailableList'.
     *
     * Indica si la lista del recurso está disponible o no,
     * usado por ejempolo cuando se está haciendo una petición remota,
     * o cuando se está eliminando un elemento e indicar al usuario que
     * se está realizando actualmente una acción.
     *
     * @returns {Boolean} Al ejecutar acciones como 'crear', 'editar' o 'eliminar'.
     */
    getAvailableList: state => {
      return !state.isCreatingResource && !state.isGettingResource && !state.isDeletingResource
    },

    /**
     * 'isSearchingMode'.
     *
     * Indica si hay un texto de búsqueda para filtrado de datos.
     */
    isSearchingMode: state => Boolean(state.query)
  },

  mutations: {
    /**
     * 'resetState'.
     *
     * Elimina todas las variables del state, las formatea a nulo.
     */
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setResource (state, resource) {
      state.resource = resource
    },

    setSelectData (state, select) {
      state.select = select
    },

    setDeptosData (state, deptos) {
      state.deptos = deptos
    },

    setSelectedDepto (state, selectedDepto) {
      state.selectedDepto = selectedDepto
    },

    setResourceToEdit (state, resource) {
      state.editingResource = resource
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCreatingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    },

    setQuery (state, payload) {
      state.query = payload
    }
  },

  actions: {
    /**
     * Hace una petición al servidor para obtener todos usuarios.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getResource ({ state, getters, commit, dispatch }, page = null) {
      if (getters.isSearchingMode) return dispatch('search', { search: state.query, page })

      const { error, message, data } =
        page !== null
          ? await resource.get(`${apiName}?page=${page}`, null, 'setIsGettingResource')
          : await resource.all({}, 'setIsGettingResource')

      if (error) return { error, message }

      commit('setResource', data)

      return data
    },

    async getSelectListAll ({ commit }) {
      const { error, message, data } = await resource.get(`${apiName}/list`)
      if (error) return { error, message }

      commit('setSelectData', data)

      return data
    },

    async getSelectTree ({ commit }) {
      const { error, message, data } = await resource.get(`${apiName}/tree`)
      if (error) return { error, message }
      return data
    },

    async search ({ commit }, { search, page }) {
      const { error, message, data } = await resource.get(
        `${apiName}/search`,
        { search, page },
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setResource', data.data)

      return data
    },

    /**
     * Petición asíncrona para crear un nuevo usuario.
     *
     * @param {string} payload.email Email del usuario
     * @param {string} payload.password Contraseña del usuario
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async createResource ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await resource.create(payload)

        retval = data

        dispatch('getResource')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Obtiene la información de un usuario en específico.
     *
     * Se obtiene un usuario basado en su id y la información
     * se guarda en 'setUserToEdit'.
     *
     * @param {int} id Id del usuario a obtener.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getResourceToEdit ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await resource.show(id)

        retval = data

        commit('setResourceToEdit', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Actualiza la información de un usuario.
     *
     * @param {int} id Id del usuario a actualizar.
     * @param {string} payload.nombre Nombre de la persona.
     * @param {string} payload.email Email del usuario.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async updateResource ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await resource.update(id, payload)

        retval = data

        commit('setResourceToEdit', null)

        dispatch('getResource')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Elimina la información de un usuario.
     *
     * @param {int} id Id del usuario a eliminar.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async deleteResource ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await resource.delete(id, 'setIsDeletingResource')

        retval = data

        dispatch('getResource')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
