import Resource from '@/api/Resource'
import service from '@/api/service'

const apiName = '/api/inversionistas'
const modName = 'inversionistaModule'
const resource = new Resource(apiName, modName)

export default {
  namespaced: true,

  state: {
    /**
     * Referencia al recurso obtenido de la API del backend.
     */
    resource: null,

    select: null,

    deptos: null,

    selectedDepto: null,

    /**
     * Término ingresado para buscar un componente en la API.
     */
    query: null,

    /**
     * Cuando se obtiene la información de un recurso a actualizar,
     * se almacena en esta variable, se declara como nulo inicialmente
     * para saber si actualmente se está editando o no.
     */
    editingResource: null,

    isLoadingResource: false,

    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false
  },

  getters: {
    /**
     * 'isThereUsers'.
     *
     * Retorna para indicar si el resource del módulo tiene datos
     */
    isThereData: ({ resource }) => Boolean(resource && resource.data),

    isThereSelect: ({ select }) => Boolean(select && select.data),

    isThereDeptos: ({ deptos }) => Boolean(deptos && deptos.data),

    isThereSelectedDepto: ({ selectedDepto }) => Boolean(selectedDepto && selectedDepto.data),

    getList: ({ resource }, getters) => (getters.isThereData ? resource.data : []),

    getResource: (state, getters) => (getters.isThereData ? state.resource : {}),

    getSelectData: (state, getters) => (getters.isThereSelect ? state.select.data : []),

    getDeptosData: (state, getters) => (getters.isThereDeptos ? state.deptos.data : []),

    getSelectedDeptoData: (state, getters) => (getters.isThereSelectedDepto ? state.selectedDepto.data : null),

    getTotalItems: (state, getters) => (getters.isThereData ? state.resource.data.length : 0),

    /**
     * 'isEditingResource'.
     *
     * Indica si actualmente se está o no editando un recurso.
     */
    isEditingResource: state => Boolean(state.editingResource),

    /**
     * 'getAvailableList'.
     *
     * Indica si la lista del recurso está disponible o no,
     * usado por ejempolo cuando se está haciendo una petición remota,
     * o cuando se está eliminando un elemento e indicar al usuario que
     * se está realizando actualmente una acción.
     *
     * @returns {Boolean} Al ejecutar acciones como 'crear', 'editar' o 'eliminar'.
     */
    getAvailableList: state => {
      return !state.isCreatingResource && !state.isGettingResource && !state.isDeletingResource
    },

    /**
     * 'isSearchingMode'.
     *
     * Indica si hay un texto de búsqueda para filtrado de datos.
     */
    isSearchingMode: state => Boolean(state.query)
  },

  mutations: {
    /**
     * 'resetState'.
     *
     * Elimina todas las variables del state, las formatea a nulo.
     */
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setResource (state, resource) {
      state.resource = resource
    },

    setSelectData (state, select) {
      state.select = select
    },

    setDeptosData (state, deptos) {
      state.deptos = deptos
    },

    setSelectedDepto (state, selectedDepto) {
      state.selectedDepto = selectedDepto
    },

    setResourceToEdit (state, resource) {
      state.editingResource = resource
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCreatingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    },

    setQuery (state, payload) {
      state.query = payload
    }
  },

  actions: {
    /**
     * Hace una petición al servidor para obtener todos usuarios.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getResource ({ state, getters, commit, dispatch }, payload = null) {
      if (getters.isSearchingMode) return dispatch('search', { search: state.query, page: payload.page })

      const { error, message, data } = payload
        ? (payload.subcondominio_id
          ? await resource.get(`${apiName}?empresa_id=${payload.empresa_id}&condominio_id=${payload.condominio_id}&subcondominio_id=${payload.subcondominio_id}`, {}, 'setIsGettingResource')
          : (payload.condominio_id
            ? await resource.get(`${apiName}?empresa_id=${payload.empresa_id}&condominio_id=${payload.condominio_id}`, {}, 'setIsGettingResource')
            : await resource.get(`${apiName}?empresa_id=${payload.empresa_id}`, {}, 'setIsGettingResource')))
        : await resource.get(`${apiName}`, {}, 'setIsGettingResource')

      if (error) return { error, message }

      commit('setResource', data)

      return data
    },

    async getSelectList ({ commit }) {
      const { error, message, data } = await resource.get(`${apiName}/list?forma_pago=EFECTIVO`)
      if (error) return { error, message }

      commit('setSelectData', data)

      return data
    },

    async getSelectListAll ({ commit }, payload = null) {
      const { error, message, data } = payload
        ? (payload.subcondominio_id
          ? await resource.get(`${apiName}/list?empresa_id=${payload.empresa_id}&condominio_id=${payload.condominio_id}&subcondominio_id=${payload.subcondominio_id}`)
          : (payload.condominio_id
            ? await resource.get(`${apiName}/list?empresa_id=${payload.empresa_id}&condominio_id=${payload.condominio_id}`)
            : await resource.get(`${apiName}/list?empresa_id=${payload.empresa_id}`)))
        : await resource.get(`${apiName}/list`)
      if (error) return { error, message }

      commit('setSelectData', data)

      return data
    },

    async getSelectDeptos ({ commit }, inversionista_id) {
      if (!inversionista_id) return

      const { error, message, data } = await resource.get(`${apiName}/deptos?inversionista_id=${inversionista_id}`)
      if (error) return { error, message }

      commit('setDeptosData', data)

      return data
    },

    async getSelectedDepto ({ commit }, departamento_id) {
      if (!departamento_id) return

      const { error, message, data } = await resource.get(`${apiName}/info-depto?departamento_id=${departamento_id}`)
      if (error) return { error, message }

      commit('setSelectedDepto', data)

      return data
    },

    async search ({ commit }, { search, page }) {
      const { error, message, data } = await resource.get(
        `${apiName}/search`,
        { search, page },
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setResource', data.data)

      return data
    },

    /**
     * Petición asíncrona para crear un nuevo usuario.
     *
     * @param {string} payload.email Email del usuario
     * @param {string} payload.password Contraseña del usuario
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async createResource ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await resource.create(payload)

        retval = data

        dispatch('getResource')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Importa un archivo layout para la creación o actualización de los departamentos
     *
     * @param {file}        payload.file
     * @param {Boolean}     payload.save_file
     */
    /**
     * Importa un archivo layout para actualización de la programación de pagos
     *
     * @param {file}        payload.file
     * @param {int}         payload.nomina_id
     * @param {int}         payload.periodo_fiscal_id
     * @param {Boolean}     payload.save_file
     * @param {Boolean}     payload.overwrite
     */
    async parse_import ({ dispatch }, payload) {
      let retval = {}

      try {
        const formData = new FormData()

        formData.append('file', payload.file)
        formData.append('save_file', payload.save_file)
        formData.append('tipo_importacion', payload.tipo_importacion)

        const { data } = await service.post(`${apiName}/parse-import`, formData)

        retval = data

        dispatch('getResource', payload.periodo_id)
      } catch (error) {
        retval = { error: false, ...error.response.data }
      }

      return retval
    },

    async save_import ({ dispatch }, payload) {
      let retval = {}

      try {
        const formData = new FormData()
        formData.append('data', payload.data)
        formData.append('tipo_importacion', payload.tipo_importacion)

        const { data } = await service.post(`${apiName}/save-import`, payload.data)

        retval = data

        dispatch('getResource', payload.periodo_id)
      } catch (error) {
        retval = { error: false, ...error.response.data }
      }

      return retval
    },

    /**
     * Obtiene la información de un usuario en específico.
     *
     * Se obtiene un usuario basado en su id y la información
     * se guarda en 'setUserToEdit'.
     *
     * @param {int} id Id del usuario a obtener.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getResourceToEdit ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await resource.show(id)

        retval = data

        commit('setResourceToEdit', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Actualiza la información de un usuario.
     *
     * @param {int} id Id del usuario a actualizar.
     * @param {string} payload.nombre Nombre de la persona.
     * @param {string} payload.email Email del usuario.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async updateResource ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await resource.update(id, payload)

        retval = data

        commit('setResourceToEdit', null)

        dispatch('getResource')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Elimina la información de un usuario.
     *
     * @param {int} id Id del usuario a eliminar.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async deleteResource ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await resource.delete(id, 'setIsDeletingResource')

        retval = data

        dispatch('getResource')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async contrasenasInversionistas ({ dispatch }) {
      let retval = {}

      try {
        const { data } = await service.post('/api/inversionistas/contrasenas-inversionistas')

        retval = data

        dispatch('getResource')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
