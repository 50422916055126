<template>
  <div class="tree-menu">
    <div class="label-wrapper" @click="toggleChildren" v-if="!is_valid && condition">
      <div :class="labelClasses">
        <b>{{ label }}:</b>
        <ul>
          <li v-if="condition"><b>Condición a evaluar:</b> {{ condition }}</li>
          <li v-if="evaluation"><b>Evaluación:</b> {{ evaluation }}</li>
          <li class="text-danger" v-if="result"><b>Resultado:</b> {{ result }}</li>
        </ul>
      </div>
    </div>
    <div>
      <tree-menu
        v-for="node in nodes"
        :nodes="node.nodes"
        :label="node.label"
        :condition="node.condition"
        :evaluation="node.evaluation"
        :result="node.result"
        :is_valid="node.is_valid"
        :depth="depth + 1"
        v-bind:key="node.id"
      >
      </tree-menu>
    </div>

  </div>
</template>
<script>
export default {
  props: [
    'label',
    'condition',
    'evaluation',
    'result',
    'is_valid',
    'nodes',
    'depth'
  ],
  data () {
    return { showChildren: false }
  },
  name: 'tree-menu',
  computed: {
    validIcon () {
      return this.is_valid ? 'check' : 'x'
    },
    iconClasses () {
      return this.showChildren ? 'folder-plus' : 'folder-minus'
    },
    labelClasses () {
      return { 'has-children': this.nodes }
    },
    indent () {
      return { transform: `translate(${this.depth * 50}px)` }
    }
  },
  methods: {
    toggleChildren () {
      this.showChildren = !this.showChildren
    }
  }
}
</script>
