<template>
  <div>
    <!-- Acceso a las operaciones principales del inversionista -->
    <div class="row">
      <div class="col-xl-4 mb-4">
        <div class="card lift h-100 my-cursor" @click="submitFactura" v-if="actualizar">
          <div class="card-body d-flex justify-content-center flex-column">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-3">
                <i class="feather-xl text-blue mb-3" data-feather="arrow-up-circle"></i>
                <h5>Subir factura</h5>
                <div class="text-muted small">
                  Envía tu factura para validación y programación de pago.
                </div>
              </div>
              <img src="@/assets/img/illustrations/Invoice-rafiki.svg" style="width: 8rem" />
            </div>
          </div>
        </div>
        <div class="card lift h-100 my-cursor" v-else>
          <div class="card-body d-flex justify-content-center flex-column">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-3">
                <i class="feather-xl text-blue mb-3" data-feather="arrow-up-circle"></i>
                <h5>Subir factura</h5>
                <div class="text-muted small">
                  Envía tu factura para validación y programación de pago.
                </div>
              </div>
              <img src="@/assets/img/illustrations/Invoice-rafiki.svg" style="width: 8rem" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 mb-4">
        <router-link
          :to="{ name: 'FacturasInversionistasView' }"
          class="card lift h-100"
        >
          <div class="card-body d-flex justify-content-center flex-column">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-3">
                <i class="feather-xl text-green mb-3" data-feather="check-square"></i>
                <h5>Facturas</h5>
                <div class="text-muted small">
                  Verifica el estado de tus facturas
                </div>
              </div>
              <img src="@/assets/img/illustrations/Printing invoices-bro.svg" alt="..." style="width: 8rem" />
            </div>
          </div>
        </router-link>
      </div>

      <b-modal
        v-model="modal_error"
        centered
        size="lg"
        scrollable
        hide-footer
        dialog-class="x-modal-md"
      >
        <template #modal-title>
          <span class="text-danger">Error, no se puede enviar un comprobante fiscal si las actualizaciones por INPC no han sido aplicadas a sus contratos</span>
        </template>
      </b-modal>
      <b-modal
        v-model="modal"
        centered
        size="lg"
        scrollable
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        hide-header-close
        dialog-class="x-modal-md"
      >
        <template #modal-title>Importar facturas</template>
        <template #default="{cancel}">
          <factura-inversionista-import @on-sent="verDatosFactura" is-on-dashboard>
            <template #btn-cancel-place="{ clearForm }">
              <b-button
                variant="black"
                size="sm"
                @click="
                  cancel()
                  clearForm()
                "
              >
                Cerrar
              </b-button>
            </template>
          </factura-inversionista-import>
        </template>
      </b-modal>

      <div class="col-xl-4 mb-4">
        <router-link :to="{ name: 'DepartamentosInversionistaView' }" class="card lift h-100">
          <div class="card-body d-flex justify-content-center flex-column">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-3">
                <i class="feather-xl text-pink-soft mb-3" data-feather="home"></i>
                <h5>Propiedades</h5>
                <div class="text-muted small">Consulta tus propiedades registradas.</div>
              </div>
              <img src="@/assets/img/illustrations/House searching-amico.svg" alt="..." style="width: 8rem" />
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <!-- Dashboard -->
    <div class="row">
      <div class="col-xl-6 mb-6">
        <div class="card card-header-actions mb-3">
          <div class="card-header">
            <div class="mr-3">
              <i class="feather mt-1" data-feather="arrow-up-circle"></i>
              <span class="ml-2">Envía o actualiza tu constancia de situación fiscal</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 mb-6">
        <div class="card card-header-actions mb-3" @click="descargaCSF">
          <div class="card-header">
            <div class="mr-3">
              <i class="feather mt-1" data-feather="arrow-down-circle"></i>
              <span class="ml-2">Descarga las constancias de situación fiscal de las empresas a las que facturas</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-3 col-xxl-3">
        <div class="card card-header-actions mb-3" @click="modal_contratos = true">
          <div class="card-header">
            Contratos por vencer: {{ contratosData.length }}
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3">
        <div class="card card-header-actions mb-3">
          <div class="card-header">
            Propiedades pagadas: {{ propiedades.pagadas }}
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3">
        <div class="card card-header-actions mb-3">
          <div class="card-header">
            Propiedades pendientes: {{ propiedades.pendientes }}
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3">
        <div class="card card-header-actions mb-3">
          <div v-if="propiedades.actualizar > 0" class="card-header text-danger" v-b-tooltip.hover="'El importe de su contrato no se ha actualizado conforme el INPC'">
            Importes por actualizar: {{ propiedades.actualizar }}
          </div>
          <div v-else class="card-header">
            Importes por actualizar: {{ propiedades.actualizar }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-12">
        <div class="card mb-4">
          <div class="card-header">
            Resúmen de operaciones
          </div>
          <div class="card-body">
            <b-table
              id="facturasTable"
              v-if="getAvailableList"
              :fields="fields"
              :items="getResourceData"
              striped
              bordered
              responsive
              hover
              stacked="lg"
              show-empty
              small
            >
              <!-- Eliminado -->
              <template #cell(is_deleted)="row">
                <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
                <b-badge v-else variant="light">Sí</b-badge>
              </template>

              <template #cell(inversionista.rfc)="row">
                <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
                {{ row.item.inversionista.rfc }}
                </span>
              </template>

              <template #cell(cfdi_comprobante.complemento.uuid)="row">
                <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
                {{ row.item.cfdi_comprobante.complemento.uuid }}
                </span>
              </template>

              <template #cell(importe)="row">
                <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
                {{ $formatNumber(row.item.importe) }}
                </span>
              </template>

              <template #cell(inversionista.clabe)="row">
                <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
                {{ row.item.inversionista.clabe }}
                </span>
              </template>

              <template #cell(fecha_programacion)="row">
                <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
                {{ row.item.fecha_programacion }}
                </span>
              </template>

              <template #cell(fecha_pago)="row">
                <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
                {{ row.item.fecha_pago }}
                </span>
              </template>

              <template #cell(status)="row">
                <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
                {{ row.item.status }}
                </span>
              </template>

              <template #cell(departamentos)="row">
                <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
                  <div if="row.item.cfdi_comprobante.conceptos">
                    <ul>
                      <li v-for="concepto in row.item.cfdi_comprobante.conceptos" v-bind:key="concepto.id">
                        {{ concepto.departamento.num_catastral }}
                      </li>
                    </ul>
                  </div>
                </span>
              </template>
            </b-table>
            <loading v-else></loading>
          </div>
        </div>
      </div>
      <!--
      <div class="col-xxl-4">
        <div class="row">
          <div class="col-xl-6 col-xxl-12">
            <div class="card mb-4">
              <div class="card-header">Facturas pendientes</div>
              <div class="card-body">
                <div v-if="getAvailableList">
                  <div v-for="pendiente in getPendientesList" v-bind:key="pendiente" class="d-flex align-items-center justify-content-between mb-4">
                    <div class="d-flex align-items-center flex-shrink-0 mr-3">
                      <div class="avatar avatar-xl mr-3 bg-gray-200">
                        <img
                          class="avatar-img img-fluid"
                          src="@/assets/img/illustrations/profiles/house.png"
                          alt=""
                        />
                      </div>
                      <div class="d-flex flex-column font-weight-bold">
                        <a class="text-dark line-height-normal mb-1">
                          Departamento: {{ pendiente.departamento }}
                        </a>
                        <div class="small text-muted line-height-normal">
                          Período: {{ pendiente.periodo }}
                        </div>
                      </div>
                    </div>
                    <div class="dropdown no-caret">
                      <button
                        class="btn btn-transparent-dark btn-icon dropdown-toggle"
                        id="dropdownPeople1"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right animated--fade-in-up"
                        aria-labelledby="dropdownPeople1"
                      >
                        <a class="dropdown-item" href="#!">Action</a>
                        <a class="dropdown-item" href="#!">Another action</a>
                        <a class="dropdown-item" href="#!">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <loading v-else></loading>
              </div>
            </div>
          </div>
        </div>
      </div>
      -->
    </div>

    <!-- Histórico mensual de pagos y retenciones -->
    <div class="row">
      <div class="col-xxl-8">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            Histórico mensual de pagos y retenciones
          </div>
          <div class="card-body">
            <Bar
              :chart-options="chartPagosOptions"
              :chart-data="chartPagosData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"
              v-if="getAvailableList"
            />
            <loading v-else></loading>
          </div>
        </div>
      </div>
      <div class="col-xxl-3">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            Avance anual
          </div>
          <div class="card-body">
            <Pie
              :chart-options="pieOptions"
              :chart-data="piePagosData"
              chart-id="pieId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="25"
              :height="25"
              v-if="getAvailableList"
            />
            <loading v-else></loading>
          </div>
        </div>
      </div>
    </div>

    <!-- Total y listado de contratos por vencer en un periódo de 12 meses -->
    <b-modal
      v-model="modal_contratos"
      centered
      size="lg"
      scrollable
      no-close-on-backdrop
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span class="text-primary">Contratos por vencer en un período de 12 meses ({{ contratosData.length }})</span>
      </template>
      <b-table
        id="contratosTable"
        v-if="getAvailableList"
        :fields="contratosFields"
        :items="contratosData"
        striped
        bordered
        responsive
        hover
        stacked="lg"
        show-empty
        small
      >
        <!-- Eliminado -->
        <template #cell(is_deleted)="row">
          <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
          <b-badge v-else variant="light">Sí</b-badge>
        </template>

        <template #cell(inversionista.rfc)="row">
          <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.inversionista.rfc }}
          </span>
        </template>

        <template #cell(cfdi_comprobante.complemento.uuid)="row">
          <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.cfdi_comprobante.complemento.uuid }}
          </span>
        </template>

        <template #cell(importe)="row">
          <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ $formatNumber(row.item.importe) }}
          </span>
        </template>

        <template #cell(inversionista.clabe)="row">
          <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.inversionista.clabe }}
          </span>
        </template>

        <template #cell(fecha_programacion)="row">
          <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.fecha_programacion }}
          </span>
        </template>

        <template #cell(fecha_pago)="row">
          <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.fecha_pago }}
          </span>
        </template>

        <template #cell(status)="row">
          <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.status }}
          </span>
        </template>

        <template #cell(departamentos)="row">
          <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
            <div if="row.item.cfdi_comprobante.conceptos">
              <ul>
                <li v-for="concepto in row.item.cfdi_comprobante.conceptos" v-bind:key="concepto.id">
                  {{ concepto.departamento.num_catastral }}
                </li>
              </ul>
            </div>
          </span>
        </template>
      </b-table>
      <loading v-else></loading>
      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FacturaInversionistaImport from '../../components/Inversionistas/Facturas/FacturaInversionistaImport'
import { Bar, Pie } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

const STORE_MODULE = 'dashboardInversionistaModule'
const PERIODO_MODULE = 'periodoModule'

export default {
  name: 'DashboardInversionista',

  components: {
    FacturaInversionistaImport,
    Bar,
    Pie
  },

  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 184
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      periodo: null,
      modal: false,
      modal_error: false,
      modal_contratos: false,
      actualizar: false,

      propiedades: {
        total: 0,
        pagadas: 0,
        pendientes: 0,
        actualizar: 0
      },

      fields: [
        { key: 'cfdi_comprobante.fecha', label: 'Fecha Comprobante' },
        { key: 'cfdi_comprobante.complemento.uuid', label: 'Folio Fiscal' },
        { key: 'cfdi_comprobante.folio', label: 'Folio Interno' },
        { key: 'cfdi_comprobante.version', label: 'Versión' },
        { key: 'importe', label: 'Importe' },
        { key: 'status', label: 'Status' },
        { key: 'periodo.descriptor', label: 'Período' },
        { key: 'departamentos', label: 'Departamentos' }
      ],

      contratosFields: [
        { key: 'num_catastral', label: 'No. Catastral' },
        { key: 'cuenta_predial', label: 'No. Cuenta Predial' },
        { key: 'inicio_contrato', label: 'Fecha inicio' },
        { key: 'termino_contrato', label: 'Fecha término' },
        { key: 'identificacion', label: 'Inversionista' },
        { key: 'rfc', label: 'RFC' }
      ],
      contratosData: [],

      chartPagosData: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        datasets: []
      },
      chartPagosOptions: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
            }
          }
        }
      },
      piePagosData: {
        labels: ['Pagado', 'Pendiente'],
        datasets: [
          {
            data: [0, 0],
            backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
            borderColor: ['rgba(54, 162, 235)', 'rgba(255, 99, 132)'],
            borderWidth: 1
          }
        ]
      },
      pieOptions: {
        responsive: true
      }
    }
  },

  created () {
    this.init()
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceData',
      'getAvailableList',
      'isThereData',
      'getResource'
    ])
  },

  watch: {
    periodo: {
      immediate: true,
      handler (value) {
        if (value) {
          this.operaciones(value)
          this.retenciones(value)
          this.contratos(value)
          this.resumen(value)
        }
      }
    }
  },

  methods: {
    async init () {
      // Inicializamos secciones
      this.periodoActual()
    },

    async periodoActual () {
      // Obtenemos el período que corresponda al mes y año actual
      const result1 = await this.$store.dispatch(`${PERIODO_MODULE}/getPeriodoActual`)
      if (result1.error) {
        const { error, message } = result1

        this.$notify({ error, message }, 'Rentas')
        return
      }
      this.periodo = result1.data.periodo
    },

    async operaciones (periodo) {
      const result2 = await this.$store.dispatch(`${STORE_MODULE}/getOperaciones`, periodo.id)
      if (result2.error) {
        const {
          error,
          message
        } = result2
        this.$notify({
          error,
          message
        }, 'Rentas')
      }
    },

    async retenciones (periodo) {
      const result2 = await this.$store.dispatch(`${STORE_MODULE}/getPagosRetenciones`, periodo.id)
      if (result2.error) {
        const {
          error,
          message
        } = result2
        this.$notify({
          error,
          message
        }, 'Rentas')
      }
      if (result2.data.tipo_persona === 'FISICA') {
        this.chartPagosData.datasets = [{
          label: 'Pagos',
          data: result2.data.pagos,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1
        }, {
          label: 'IVA',
          data: result2.data.traslados,
          backgroundColor: 'rgba(192, 75, 192, 0.2)',
          borderColor: 'rgba(192, 75, 192)',
          borderWidth: 1
        }, {
          label: 'Retenciones ISR (001)',
          data: result2.data.retenciones_isr,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192)',
          borderWidth: 1
        }, {
          label: 'Retenciones IVA (002)',
          data: result2.data.retenciones_iva,
          backgroundColor: 'rgba(255, 205, 86, 0.2)',
          borderColor: 'rgba(255, 205, 86)',
          borderWidth: 1
        }]
      } else {
        this.chartPagosData.datasets = [{
          label: 'Pagos',
          data: result2.data.pagos,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1
        }, {
          label: 'IVA',
          data: result2.data.traslados,
          backgroundColor: 'rgba(192, 75, 192, 0.2)',
          borderColor: 'rgba(192, 75, 192)',
          borderWidth: 1
        }]
      }
    },

    async contratos (periodo) {
      const result = await this.$store.dispatch(`${STORE_MODULE}/getContratosVencimiento`, { periodo_id: periodo.id, meses: 12 })
      if (result.error) {
        const { error, message } = result
        this.$notify({ error, message }, 'Contratos')
      }
      this.contratosData = result.data.contratos
    },

    async resumen (periodo) {
      const result3 = await this.$store.dispatch(`${STORE_MODULE}/getResumen`, periodo.id)
      if (result3.error) {
        const { error, message } = result3
        this.$notify({ error, message }, 'Rentas')
      }
      this.propiedades = result3.data.propiedades
      this.actualizar = true
    },

    async descargaCSF () {
      const result = await this.$store.dispatch(`${STORE_MODULE}/downloadConstancias`)

      await this.$onDownload(result, 'constancias.zip')

      this.$notify({ message: 'Descarga de archivo de constancias de situación fiscal.' })
    },

    async submit () {
      const { error, message } = await this.$store.dispatch(`${STORE_MODULE}/getOperaciones`)
      await this.$store.dispatch(`${STORE_MODULE}/getPendientes`)

      if (error) this.$notify({ error, message }, 'Facturas')
    },

    submitFactura () {
      if (this.loading) return

      if (this.propiedades.actualizar > 0) {
        this.modal_error = true
      } else {
        this.modal = true
      }
    },

    verDatosFactura (datos) {
      // Verificamos que todos los comprobantes se hayan validado (si todos son correctos cerramos el modal)
      if (datos.data.every((row) => row.result.valido)) {
      }
    }
  }
}
</script>

<style scoped>

</style>
