import service from '@/api/service'
const apiName = '/api/estado-cuenta'

export default {
  namespaced: true,

  actions: {
    async getPreview ({ context }, payload) {
      let retval = {}
      try {
        const { data } = await service({
          _method: 'GET',
          url: `${apiName}/preview?inversionista_id=${payload.inversionista_id}`
        })
        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }
      return retval
    },

    async download ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await service({
          _method: 'GET',
          url: `${apiName}/download?inversionista_id=${payload.inversionista_id}`,
          responseType: 'blob'
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async notify ({ context }, payload) {
      let retval = {}
      try {
        const { data } = await service({
          _method: 'GET',
          url: `${apiName}/notificar?inversionista_id=${payload.inversionista_id}`
        })
        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }
      return retval
    }
  }
}
