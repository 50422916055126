<template>
  <div>
    <div class="sidenav-menu-heading">Operaciones</div>

    <sidebar-link
      :requiresAuthorizations="['indice comprobantes']"
      :to="{ name: 'ComprobantesMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="file-text"></i>
      </div>
      Comprobantes Fiscales
    </sidebar-link>

    <sidebar-link
      :requiresAuthorizations="['indice comprobantes']"
      :to="{ name: 'RentasMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="file-plus"></i>
      </div>
      Pagos con Comprobante Fiscal
    </sidebar-link>

    <sidebar-link
      :requiresAuthorizations="['indice pagos sin comprobante']"
      :to="{ name: 'PagosSinComprobanteMainView' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="file-minus"></i>
      </div>
      Pagos sin Comprobante Fiscal
    </sidebar-link>

    <sidebar-link
      :requiresAuthorizations="['indice contratos']"
      :to="{ name: 'contratos' }"
      :show-permission-help="false"
    >
      <div class="nav-link-icon">
        <i data-feather="list"></i>
      </div>
      Contratos
    </sidebar-link>
  </div>
</template>

<script>
import SidebarLink from './SidebarLink'

export default {
  name: 'SidebarSectionOperaciones',

  props: {
    activeAccordeon: {
      required: true
    }
  },

  components: {
    SidebarLink
  }
}
</script>

<style scoped>

</style>
