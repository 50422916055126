<template>
  <b-modal
    v-model="modal_pendientes"
    centered
    size="lg"
    scrollable
    no-close-on-backdrop
    dialog-class="x-modal-md"
  >
    <template #modal-title>
      <span class="text-primary">Lista de contratos pendientes</span>
    </template>
    <b-card no-body>
      <b-card-header>
        <div class="ml-auto d-flex justify-content-end align-items-center">
          <search-bar
            placeholder="Buscar"
            @filter="(filter) => this.filter = filter"></search-bar>
          <b-button @click="enviarNotificacion" class="ml-3" size="sm" :disabled="filtered.length === 0">
            Enviar notificación
          </b-button>
          <b-button @click="exportarPendientes" class="ml-3" size="sm" variant="success" :disabled="filtered.length === 0">
            Exportar listado
          </b-button>
        </div>
      </b-card-header>
      <b-card-body class="p-2">
        <b-table
          id="contratosPendientesTable"
          v-if="!loading"
          :fields="contratosPendientesFields"
          :items="contratosPendientesData"
          striped
          bordered
          responsive
          hover
          stacked="lg"
          show-empty
          small
          :filter="filter"
          :filter-function="filterFunction"
          @filtered="onFiltered"
          empty-filtered-text="No hay contratos pendientes con el criterio de búsqueda escrito"
        >
          <!-- Eliminado -->
          <template #cell(is_deleted)="row">
            <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
            <b-badge v-else variant="light">Sí</b-badge>
          </template>

          <template #cell(num_catastral)="row">
            <filtered-row :value="row.item.num_catastral" :filter="filter"/>
          </template>

          <template #cell(cuenta_predial)="row">
            <filtered-row :value="row.item.cuenta_predial" :filter="filter"/>
          </template>

          <template #cell(inicio_contrato)="row">
            <filtered-row :value="row.item.inicio_contrato" :filter="filter"/>
          </template>

          <template #cell(termino_contrato)="row">
            <filtered-row :value="row.item.termino_contrato" :filter="filter"/>
          </template>

          <template #cell(inversionista.identificacion)="row">
            <filtered-row :value="row.item.inversionista.identificacion" :filter="filter"/>
          </template>

          <template #cell(inversionista.rfc)="row">
            <filtered-row :value="row.item.inversionista.rfc" :filter="filter"/>
          </template>
        </b-table>
        <loading v-else></loading>
      </b-card-body>
    </b-card>
    <template #modal-footer>
      <!-- Enviar para crear o actualizar -->
      <div class="w-100">
      </div>
    </template>
  </b-modal>
</template>

<script>
import SearchBar from '../../Common/Views/SearchBar.vue'
import FilteredRow from '../../Common/Table/FilteredRow.vue'
const STORE_MODULE = 'dashboardAdministradorModule'
export default {
  name: 'ModalContratosPendientes',

  props: {
    modal: {
      required: true
    },
    periodo: {
      required: true
    }
  },

  components: {
    FilteredRow,
    SearchBar
  },

  data () {
    return {
      contratosPendientesFields: [
        { key: 'num_catastral', label: 'No. Catastral' },
        { key: 'cuenta_predial', label: 'No. Cuenta Predial' },
        { key: 'inicio_contrato', label: 'Fecha inicio' },
        { key: 'termino_contrato', label: 'Fecha término' },
        { key: 'inversionista.identificacion', label: 'Inversionista' },
        { key: 'inversionista.rfc', label: 'RFC' }
      ],
      contratosPendientesData: [],
      loading: false,
      modal_pendientes: false,
      filter: '',
      filtered: []
    }
  },

  watch: {
    periodo: {
      immediate: true,
      handler (value) {
        if (value) {
          this.contratos_pendientes(value)
        }
      }
    },
    modal: {
      immediate: true,
      handler (value) {
        if (value) {
          this.modal_pendientes = value
        }
      }
    },
    modal_pendientes: {
      immediate: true,
      handler (value) {
        if (!value) {
          this.$emit('closed')
        }
      }
    }
  },

  methods: {
    async contratos_pendientes (periodo) {
      this.loading = true
      const result = await this.$store.dispatch(`${STORE_MODULE}/getContratosPendientes`, { periodo_id: periodo.id, meses: 12 })
      this.loading = false
      if (result.error) {
        const { error, message } = result
        this.$notify({ error, message }, 'Contratos')
      }
      this.contratosPendientesData = result.data.contratos
      this.filtered = result.data.contratos
      this.$emit('retrieveData', this.contratosPendientesData.length)
    },

    async enviarNotificacion () {
      const departamentos_ids = this.filtered.map(item => item.id)
      const result = await this.$store.dispatch(`${STORE_MODULE}/enviarNotificaciones`,
        { periodo_id: this.periodo.id, departamentos_ids: departamentos_ids })
      if (result.error) {
        const { error, message } = result
        this.$notify({ error, message }, 'Contratos pendientes')
        return
      }
      this.$notify({ message: 'Notificaciones enviadas' })
    },

    async exportarPendientes () {
      const departamentos_ids = this.filtered.map(item => item.id)
      const result = await this.$store.dispatch(`${STORE_MODULE}/exportarPendientes`,
        { periodo_id: this.periodo.id, departamentos_ids: departamentos_ids })
      await this.$onDownload(result, `facturas_pendientes_${this.periodo.descriptor}.xlsx`)

      this.$notify({ message: `Exportación de facturas pendientes en el período ${this.periodo.descriptor}.` })
    },

    filterFunction (row, filter) {
      if (!filter || filter === '') {
        return false
      }
      return (row.num_catastral && row.num_catastral.toLowerCase().includes(filter.toLowerCase())) ||
        (row.cuenta_predial && row.cuenta_predial.toLowerCase().includes(filter.toLowerCase())) ||
        (row.inicio_contrato && row.inicio_contrato.toLowerCase().includes(filter.toLowerCase())) ||
        (row.termino_contrato && row.termino_contrato.toLowerCase().includes(filter.toLowerCase())) ||
        (row.inversionista && row.inversionista.identificacion && row.inversionista.identificacion.toLowerCase().includes(filter.toLowerCase())) ||
        (row.inversionista && row.inversionista.rfc && row.inversionista.rfc.toLowerCase().includes(filter.toLowerCase()))
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filtered = filteredItems
    }
  }
}
</script>

<style scoped>

</style>
