import Resource from '@/api/Resource'

const resource = new Resource('/api/sat', 'informacionCatalogosModule')

export default {
  namespaced: true,

  state: {
    resource: null,

    nombres: null
  },

  getters: {
    getData: ({ resource }) => resource ? resource.data : [],

    getResourceList: ({ resource }) => resource ? resource.data : [],

    getAvailableList: resource => {
      return resource
    },

    getDataNombres: ({ nombres }) => nombres ? nombres.data : [],

    getResourceListNombres: ({ nombres }) => nombres ? nombres.data : [],

    getAvailableListNombres: nombres => {
      return nombres
    }
  },

  mutations: {
    setResource (state, payload) {
      state.resource = payload
    },

    setNombres (state, payload) {
      state.nombres = payload
    }
  },

  actions: {
    async getResource ({ commit }, { sat_version_cfdi_id, sat_nombre_catalogo_id, page = null }) {
      let retval = {}

      try {
        const { data } =
          page !== null ? await resource.get(`/api/sat/informacion-catalogos?sat_version_cfdi_id=${sat_version_cfdi_id}&sat_nombre_catalogo_id=${sat_nombre_catalogo_id}&page=${page}`)
            : await resource.get(`/api/sat/informacion-catalogos?sat_version_cfdi_id=${sat_version_cfdi_id}&sat_nombre_catalogo_id=${sat_nombre_catalogo_id}`)
        retval = data
        commit('setResource', data)
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    async getNombres ({ commit }, { sat_version_cfdi_id }) {
      let retval = {}

      try {
        const { data } = await resource.get(`/api/sat/nombre-catalogos?sat_version_cfdi_id=${sat_version_cfdi_id}`)
        retval = data
        commit('setNombres', data)
      } catch (error) {
        retval = error.response.data
      }

      return retval
    }
  }
}
