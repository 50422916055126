<template>
  <b-modal
    v-model="modal_actualizacion"
    centered
    size="lg"
    scrollable
    no-close-on-backdrop
    dialog-class="x-modal-md"
  >
    <template #modal-title>
      <span class="text-primary">Contratos con actualización de importe ({{ contratosPorActualizarData.length }})</span>
    </template>
    <b-card no-body>
      <b-card-header>
        <div class="ml-auto d-flex justify-content-end align-items-center">
          <div v-if="inpc_periodo" class="text-primary">
            <ul>
              <li>El valor del INPC para el período ({{ periodo_actual }}) es de {{ inpc_actual }}</li>
              <li>El valor del INPC para el período ({{ periodo_anterior }}) es de {{ inpc_anterior }}</li>
              <li>El factor de actualización se calculó en: {{ factor_actualizacion }}</li>
            </ul>
          </div>
          <div v-else class="center align-content-center text-danger">
            <span>No se encontró información del INPC correspondiente al período {{ periodo_actual }}</span>
          </div>
          <search-bar
            placeholder="Buscar"
            @filter="(filter) => this.filter = filter"></search-bar>
          <b-button @click="exportarListado" class="ml-3" size="sm" variant="success" :disabled="filtered.length === 0">
            Exportar listado
          </b-button>
        </div>
      </b-card-header>
      <b-card-body class="p-2">
        <b-table
          id="contratosTable"
          v-if="!loading"
          :fields="contratosPorActualizarFields"
          :items="contratosPorActualizarData"
          striped
          bordered
          responsive
          hover
          stacked="lg"
          show-empty
          small
          :filter="filter"
          :filter-function="filterFunction"
          @filtered="onFiltered"
          empty-filtered-text="No hay contratos con actualización de importe con el criterio de búsqueda escrito"
        >
          <!-- Eliminado -->
          <template #cell(is_deleted)="row">
            <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
            <b-badge v-else variant="light">Sí</b-badge>
          </template>

          <template #cell(num_catastral)="row">
          <span class="text-sm text-center w-100">
            <filtered-row :value="row.item.num_catastral" :filter="filter"/>
          </span>
          </template>

          <template #cell(cuenta_predial)="row">
          <span class="text-sm text-center w-100">
            <filtered-row :value="row.item.cuenta_predial" :filter="filter"/>
          </span>
          </template>

          <template #cell(inicio_contrato)="row">
          <span class="text-sm text-center w-100">
            <filtered-row :value="row.item.inicio_contrato" :filter="filter"/>
          </span>
          </template>

          <template #cell(termino_contrato)="row">
          <span class="text-sm text-center w-100">
            <filtered-row :value="row.item.termino_contrato" :filter="filter"/>
          </span>
          </template>

          <template #cell(renta_inicial)="row">
          <span class="text-sm text-center w-100">
            {{ $formatNumber(row.item.renta_inicial) }}
          </span>
          </template>

          <template #cell(renta_actual)="row">
          <span class="text-sm text-center w-100">
            {{  $formatNumber(row.item.renta_actual) }}
          </span>
          </template>

          <template #cell(inpc_actual)="row">
            <span v-if="inpc_periodo" class="text-sm text-center w-100 text-primary">
              {{ row.value }}
            </span>
            <span v-else class="text-sm text-danger">
              No publicado
            </span>
          </template>

          <template #cell(factor)="row">
            <span v-if="inpc_periodo" class="text-sm text-center w-100 text-primary">
              {{ row.value }}
            </span>
            <span v-else class="text-sm text-danger">
              No se puede calcular
            </span>
          </template>

          <template #cell(actualizacion)="row">
            <span v-if="inpc_periodo" class="text-sm text-center w-100 text-primary">
              {{$formatNumber(row.item.actualizacion) }}
            </span>
            <span v-else class="text-sm text-danger">
              N/A
            </span>
          </template>

          <template #cell(renta_actualizada)="row">
            <span v-if="inpc_periodo" class="text-sm text-center w-100 text-primary">
              {{$formatNumber(row.value) }}
            </span>
            <span v-else class="text-sm text-danger">
              N/A
            </span>
          </template>

          <template #cell(identificacion)="row">
          <span class="text-sm text-center w-100">
            <filtered-row :value="row.item.identificacion" :filter="filter"/>
          </span>
          </template>

          <template #cell(rfc)="row">
          <span class="text-sm text-center w-100">
            <filtered-row :value="row.item.rfc" :filter="filter"/>
          </span>
          </template>

          <template #cell(forma_pago)="row">
          <span class="text-sm text-center w-100">
            {{ row.value }}
          </span>
          </template>
        </b-table>
        <loading v-else></loading>
      </b-card-body>
    </b-card>
    <template #modal-footer>
      <!-- Enviar para crear o actualizar -->
      <div class="w-100">
        <b-button
          class="btn-sm btn-primary mr-3 mb-2 mb-sm-0"
          size="sm"
          variant="primary"
          :disabled="!inpc_periodo" @click="onActualizarImporte">
          Aplicar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import SearchBar from '../../Common/Views/SearchBar.vue'
import FilteredRow from '../../Common/Table/FilteredRow.vue'

const STORE_MODULE = 'dashboardAdministradorModule'
export default {
  name: 'ModalContratosPorRenovar',
  components: {
    FilteredRow,
    SearchBar
  },

  props: {
    modal: {
      required: true
    },
    periodo: {
      required: true
    }
  },

  data () {
    return {
      contratosPorActualizarFields: [
        { key: 'num_catastral', label: 'No. Catastral' },
        { key: 'cuenta_predial', label: 'No. Cuenta Predial' },
        { key: 'inicio_contrato', label: 'Fecha inicio' },
        { key: 'termino_contrato', label: 'Fecha término' },
        { key: 'renta_inicial', label: 'Renta inicial' },
        { key: 'renta_actual', label: 'Renta actual' },
        { key: 'inpc_anterior', label: 'INPC Anterior' },
        { key: 'inpc_actual', label: 'INPC Actual' },
        { key: 'factor', label: 'Factor actualización' },
        { key: 'actualizacion', label: 'Actualización' },
        { key: 'renta_actualizada', label: 'Renta actualizada' },
        { key: 'identificacion', label: 'Inversionista' },
        { key: 'rfc', label: 'RFC' },
        { key: 'forma_pago', label: 'Forma de pago' }
      ],
      contratosPorActualizarData: [],
      modal_actualizacion: false,
      filter: '',
      filtered: [],
      loading: false,
      periodo_actual: '',
      periodo_anterior: '',
      inpc_periodo: false,
      inpc_actual: 0,
      inpc_anterior: 0,
      factor_actualizacion: 0
    }
  },

  watch: {
    modal: {
      immediate: true,
      handler (value) {
        if (value) {
          this.modal_actualizacion = value
        }
      }
    },
    modal_actualizacion: {
      immediate: true,
      handler (value) {
        if (!value) {
          this.$emit('closed')
        }
      }
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    async init () {
      // La información de actualización de importes de contrato no depende del período por lo que
      // solo se ejecuta una vez
      this.contratos_por_actualizar()
    },

    async contratos_por_actualizar () {
      this.loading = true
      const result = await this.$store.dispatch(`${STORE_MODULE}/getContratosActualizar`)
      this.loading = false
      if (result.error) {
        const { error, message } = result
        this.$notify({ error, message }, 'Contratos')
      }
      this.contratosPorActualizarData = result.data.contratos
      this.filtered = result.data.contratos
      this.$emit('retrieveData', this.contratosPorActualizarData.length)
      this.periodo_actual = result.data.periodo
      this.periodo_anterior = result.data.periodo_anterior
      this.inpc_periodo = result.data.inpc_periodo
      this.inpc_actual = result.data.inpc_actual
      this.inpc_anterior = result.data.inpc_anterior
      this.factor_actualizacion = result.data.factor_actualizacion
    },

    async onActualizarImporte () {
      // Generamos una lista de ID's de contratos
      await this.$store.dispatch(`${STORE_MODULE}/postActualizarContratos`, {
        contratos: this.contratosPorActualizarData.map((item) => item.id)
      })
      this.contratos_por_actualizar()
      this.modal_actualizacion = false
    },

    async exportarListado () {
      const departamentos_ids = this.filtered.map(item => item.id)
      const result = await this.$store.dispatch(`${STORE_MODULE}/exportarPorActualizar`,
        { periodo_id: this.periodo.id, departamentos_ids: departamentos_ids })
      await this.$onDownload(result, `contratos_por_actualizar_${this.periodo.descriptor}.xlsx`)

      this.$notify({ message: `Exportación de contratos por actualizar en el período ${this.periodo.descriptor}.` })
    },

    filterFunction (row, filter) {
      if (!filter || filter === '') {
        return false
      }
      return (row.num_catastral && row.num_catastral.toLowerCase().includes(filter.toLowerCase())) ||
        (row.cuenta_predial && row.cuenta_predial.toLowerCase().includes(filter.toLowerCase())) ||
        (row.inicio_contrato && row.inicio_contrato.toLowerCase().includes(filter.toLowerCase())) ||
        (row.termino_contrato && row.termino_contrato.toLowerCase().includes(filter.toLowerCase())) ||
        (row.identificacion && row.identificacion.toLowerCase().includes(filter.toLowerCase())) ||
        (row.rfc && row.rfc.toLowerCase().includes(filter.toLowerCase()))
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filtered = filteredItems
    }
  }
}

</script>

<style scoped>

</style>
