import service from '@/api/service'
import Resource from '@/api/Resource'

const apiName = '/api/dashboard/administrador'
const modName = 'dashboardAdministradorModule'
const resource = new Resource(apiName, modName)

export default {
  namespaced: true,

  state: {
    /**
     * Almacena todos los usuarios del sistema.
     */
    resource: null,

    resourceRentas: null,

    /**
     * Cuando se obtiene la información de un usuario a actualizar,
     * se almacena en esta variable, se declara como nulo inicialmente
     * para saber si actualmente se está editando o no un usuario.
     */
    editingResource: null,

    /**
     * Término ingresado para buscar un usuario.
     */
    query: null,

    isLoadingResource: false,

    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false,

    isGettingRentas: false,

    showRentasModal: false
  },

  getters: {
    isThereData: ({ resource }) => Boolean(resource && resource.data),

    getResourceData: (state, getters) => (getters.isThereData ? state.resource.data : []),

    getResource: (state, getters) => (getters.isThereData ? state.resource : {}),

    /**
     * 'isEditingResource'.
     *
     * Indica si actualmente se está o no editando un recurso.
     */
    isEditingResource: state => Boolean(state.editingResource),

    /**
     * 'getAvailableList'.
     *
     * Indica si la lista de usuarios está disponible o no,
     * usado por ejempolo cuando se está haciendo una petición remota,
     * o cuando se está eliminando un elemento e indicar al usuario que
     * se está realizando actualmente una acción.
     *
     * @returns {Boolean} Al ejecutar acciones como 'crear', 'editar' o 'eliminar'.
     */
    getAvailableList: state => {
      return !state.isCreatingResource && !state.isGettingResource && !state.isDeletingResource
    },

    /**
     * 'isSearchingMode'.
     *
     * Indica si hay un texto de búsqueda para filtrado de usuarios.
     */
    isSearchingMode: state => Boolean(state.query)
  },

  mutations: {
    /**
     * 'resetState'.
     *
     * Elimina todas las variables del state, las formatea a nulo.
     */
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setResource (state, resource) {
      state.resource = resource
    },

    setResourceRentas (state, resource) {
      state.resourceRentas = resource
    },

    setResourceToEdit (state, resource) {
      state.editingResource = resource
    },

    setIsGettingResource (state, value) {
      state.isGettingResource = value
    },

    setIsGettingRentas (state, value) {
      state.isGettingRentas = value
    },

    setIsCreatingResource (state, value) {
      state.isCreatingResource = value
    },

    setIsDeletingResource (state, value) {
      state.isDeletingResource = value
    },

    setQuery (state, payload) {
      state.query = payload
    },

    setShowRentasModal (state, value) {
      state.showRentasModal = value
    }
  },

  actions: {
    /**
     * Hace una petición al servidor para obtener todos usuarios.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getOperaciones ({ commit }, periodo_id) {
      const { error, message, data } = await resource.get(`${apiName}/operaciones-periodo?periodo_id=${periodo_id}`, null, 'setIsGettingResource')

      commit('setResource', data)
      if (error) return { error, message }
      return data
    },
    async getPagosRetenciones ({ commit }, periodo_id) {
      const { error, message, data } = await resource.get(`${apiName}/pagos-retenciones-periodo?periodo_id=${periodo_id}`, null, 'setIsGettingResource')

      if (error) return { error, message }
      return data
    },
    async getPagos ({ commit }, periodo_id) {
      const { error, message, data } = await resource.get(`${apiName}/pagos-periodo?periodo_id=${periodo_id}`, null, 'setIsGettingResource')

      if (error) return { error, message }
      return data
    },
    async getResumenGeneral ({ commit }, periodo_id) {
      const { error, message, data } = await resource.get(`${apiName}/resumen-general?periodo_id=${periodo_id}`, null, 'setIsGettingResource')

      if (error) return { error, message }
      return data
    },
    async getResumen ({ commit }, periodo_id) {
      const { error, message, data } = await resource.get(`${apiName}/resumen-operaciones-periodo?periodo_id=${periodo_id}`)

      if (error) return { error, message }
      return data
    },
    async getContratosVencimiento ({ commit }, { periodo_id, meses }) {
      const { error, message, data } = await resource.get(`${apiName}/contratos-por-vencer?periodo_id=${periodo_id}&meses=${meses}`)
      if (error) return { error, message }
      return data
    },
    async getContratosPagados ({ commit }, { periodo_id, meses }) {
      const { error, message, data } = await resource.get(`${apiName}/contratos-pagados?periodo_id=${periodo_id}&meses=${meses}`)

      if (error) return { error, message }
      return data
    },
    async getContratosPendientes ({ commit }, { periodo_id, meses }) {
      const { error, message, data } = await resource.get(`${apiName}/contratos-pendientes?periodo_id=${periodo_id}&meses=${meses}`)

      if (error) return { error, message }
      return data
    },
    async enviarNotificaciones ({ context }, payload) {
      let retval = {}

      try {
        const formData = new FormData()
        formData.append('periodo_id', payload.periodo_id)
        formData.append('departamentos_ids', payload.departamentos_ids.join(','))
        const { data } = await service.post(`${apiName}/enviar-notificacion`, formData)
        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }
      return retval
    },
    async exportarPendientes ({ context }, payload) {
      let retval = {}

      try {
        const formData = new FormData()
        formData.append('periodo_id', payload.periodo_id)
        formData.append('departamentos_ids', payload.departamentos_ids.join(','))
        // const { data } = await service.post(`${apiName}/enviar-notificacion`, formData)
        const { data } = await service({
          _method: 'GET',
          url: `${apiName}/exportar-pendientes`,
          responseType: 'blob',
          params: {
            periodo_id: payload.periodo_id,
            departamentos_ids: payload.departamentos_ids.join(',')
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }
      return retval
    },
    async exportarPorActualizar ({ context }, payload) {
      let retval = {}

      try {
        const formData = new FormData()
        formData.append('periodo_id', payload.periodo_id)
        formData.append('departamentos_ids', payload.departamentos_ids.join(','))
        // const { data } = await service.post(`${apiName}/enviar-notificacion`, formData)
        const { data } = await service({
          _method: 'GET',
          url: `${apiName}/exportar-por-actualizar`,
          responseType: 'blob',
          params: {
            periodo_id: payload.periodo_id,
            departamentos_ids: payload.departamentos_ids.join(',')
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }
      return retval
    },
    async getContratosActualizar ({ commit }) {
      const { error, message, data } = await resource.get(`${apiName}/contratos-por-actualizar`)

      if (error) return { error, message }
      return data
    },
    async getGraficoMeses ({ commit }, periodo_id) {
      const { error, message, data } = await resource.get(`${apiName}/grafico-meses?periodo_id=${periodo_id}`)

      if (error) return { error, message }
      return data
    },
    async getGraficoPastel ({ commit }, periodo_id) {
      const { error, message, data } = await resource.get(`${apiName}/grafico-pastel?periodo_id=${periodo_id}`)

      if (error) return { error, message }
      return data
    },
    async getTablaProyectos ({ commit }) {
      const { error, message, data } = await resource.get(`${apiName}/tabla-proyectos`)

      if (error) return { error, message }
      return data
    },
    async postActualizarContratos ({ commit }, payload) {
      let retval = {}
      const formData = new FormData()

      for (let i = 0; i < payload.contratos.length; i++) {
        formData.append('contratos[]', payload.contratos[i])
      }
      try {
        console.log('actualizar')
        const { data } = await service.post(`${apiName}/actualizar-contratos`, formData)
        console.log('post')
        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
