import Resource from '@/api/Resource'

const apiName = '/api/inversionistas/facturas'
const modName = 'facturasInversionistasModule'
const resource = new Resource(apiName, modName)

//
// Funciones para "resetear"
const importFacturas = () => ({
  files: [],
  result: []
})

export default {
  namespaced: true,

  state: {
    /**
     * Almacena todos los usuarios del sistema.
     */
    resource: null,

    pendientes: null,

    /**
     * Cuando se obtiene la información de un usuario a actualizar,
     * se almacena en esta variable, se declara como nulo inicialmente
     * para saber si actualmente se está editando o no un usuario.
     */
    editingResource: null,

    /**
     * Término ingresado para buscar un usuario.
     */
    query: null,

    //
    // Objeto para fórmulario,
    // para subir las pólizas
    importFacturas: importFacturas(),

    aplicacionFacturasResponse: [],

    isLoadingResource: false,

    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false
  },

  getters: {
    isThereData: ({ resource }) => Boolean(resource && resource.data),

    isTherePendientes: ({ pendientes }) => Boolean(pendientes && pendientes.data),

    getResourceList: ({ resource }, getters) => (getters.isThereData ? resource.data : []),

    getResource: ({ resource }, getters) => (getters.isThereData ? resource : {}),

    getTotalItems: (state, getters) => (getters.isThereData ? state.resource.data.length : 0),

    getPendientesList: ({ pendientes }, getters) => (getters.isTherePendientes ? pendientes.data : []),

    getPendientes: ({ pendientes }, getters) => (getters.isTherePendientes ? pendientes : {}),

    isCreatingResource (state) {
      return state.isCreatingResource
    },

    /**
     * 'isEditingResource'.
     *
     * Indica si actualmente se está o no editando un recurso.
     */
    isEditingResource: state => Boolean(state.editingResource),

    /**
     * 'getAvailableList'.
     *
     * Indica si la lista de usuarios está disponible o no,
     * usado por ejempolo cuando se está haciendo una petición remota,
     * o cuando se está eliminando un elemento e indicar al usuario que
     * se está realizando actualmente una acción.
     *
     * @returns {Boolean} Al ejecutar acciones como 'crear', 'editar' o 'eliminar'.
     */
    getAvailableList: state => {
      return !state.isCreatingResource && !state.isGettingResource && !state.isDeletingResource
    },

    /**
     * 'isSearchingMode'.
     *
     * Indica si hay un texto de búsqueda para filtrado de usuarios.
     */
    isSearchingMode: state => Boolean(state.query),

    getImportFacturas (state) {
      return state.importFacturas
    },

    isFormFacturasValid (state) {
      return [
        //
        // Valida que el periodo fiscal actual esté activo
        // Boolean(state.importPoliza.periodo_fiscal_id),
        //
        // Valida que el periodo fiscal esté seleccionado
        Boolean(state.importFacturas.files.length > 0)
      ].every(t => t)
    }
  },

  mutations: {
    /**
     * 'resetState'.
     *
     * Elimina todas las variables del state, las formatea a nulo.
     */
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setResource (state, resource) {
      state.resource = resource
    },

    setPendientes (state, pendientes) {
      state.pendientes = pendientes
    },

    setResourceRentas (state, resource) {
      state.resourceRentas = resource
    },

    setResourceToEdit (state, resource) {
      state.editingResource = resource
    },

    setFilesToImport (state, payload) {
      state.importFacturas.files = payload
    },

    setResultToImport (state, payload) {
      state.importFacturas.result = payload
    },

    setAplicacionFacturasResponse (state, payload) {
      state.aplicacionFacturasResponse = payload
    },

    setIsGettingResource (state, value) {
      state.isGettingResource = value
    },

    setIsGettingRentas (state, value) {
      state.isGettingRentas = value
    },

    setIsCreatingResource (state, value) {
      state.isCreatingResource = value
    },

    setIsDeletingResource (state, value) {
      state.isDeletingResource = value
    },

    setQuery (state, payload) {
      state.query = payload
    }
  },

  actions: {
    /**
     * Hace una petición al servidor para obtener todos usuarios.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getResource ({ state, getters, commit, dispatch }, page = null) {
      if (getters.isSearchingMode) return dispatch('search', { search: state.query, page })

      const { error, message, data } =
        page !== null
          ? await resource.get(`${apiName}?page=${page}`, null, 'setIsGettingResource')
          : await resource.all({}, 'setIsGettingResource')

      if (error) return { error, message }

      commit('setResource', data)

      return data
    },

    async getPendientes ({ state, getters, commit, dispatch }) {
      const { error, message, data } =
        await resource.get(`${apiName}/pendientes`, null, 'setIsGettingResource')

      if (error) return { error, message }

      commit('setPendientes', data)

      return data
    },

    async getValidations ({ state, getters, commit, dispatch }, id) {
      const { error, message, data } =
        await resource.get(`${apiName}/${id}`)

      if (error) return { error, message }
      return data
    },

    async search ({ commit }, { search, page }) {
      const { error, message, data } = await resource.get(
        `${apiName}/search`,
        { search, page },
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setResource', data.data)

      return data
    },

    /**
     * Petición asíncrona para crear un nuevo usuario.
     *
     * @param {string} payload.email Email del usuario
     * @param {string} payload.password Contraseña del usuario
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async createResource ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await resource.create(payload)

        retval = data

        dispatch('getResource')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Obtiene la información de un usuario en específico.
     *
     * Se obtiene un usuario basado en su id y la información
     * se guarda en 'setResourceToEdit'.
     *
     * @param {int} id Id del usuario a obtener.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getResourceToEdit ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await resource.show(id)

        retval = data

        commit('setResourceToEdit', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Actualiza la información de un usuario.
     *
     * @param {int} id Id del usuario a actualizar.
     * @param {string} payload.nombre Nombre de la persona.
     * @param {string} payload.email Email del usuario.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async updateResource ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await resource.update(id, payload)

        retval = data

        commit('setResourceToEdit', null)

        dispatch('getResource')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Importa una o varias pólizas y almacena el resultado
     * en una propiedad del objeto del cual se está trabajando
     * sus propiedades.
     *
     */
    async importarFacturas ({ commit, dispatch }, payload) {
      let retval = {}

      try {
        const formData = new FormData()

        for (let i = 0; i < payload.files.length; i++) {
          formData.append('files[]', payload.files[i])
        }

        const { data } = await resource.create(formData, 'setIsCreatingResource')

        commit('setResultToImport', data.data)

        //
        // Borra si ha habido alertas anteriores a un proceso
        commit('setAplicacionFacturasResponse', [])

        retval = data
      } catch (error) {
        retval = { error: false, ...error.response.data }
      } finally {
        commit('setIsCreatingResource', false)
      }

      //
      // Elimina los archivos que se han almacenado
      commit('setFilesToImport', [])
      // Obtiene el listado de recursos
      dispatch('getResource')

      return retval
    }
  }
}
