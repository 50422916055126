<template>
  <b-overlay :show="isCreatingResource">
    <template #overlay>
      <div class="text-center">
        <loading message="Importando facturas..."></loading>
      </div>
    </template>

    <b-form @submit.prevent="submit" class="momentosContables__form">
      <div class="flex-grow-1 d-flex align-items-center justify-content-center">
        <b-form-group>
          <b-form-file v-model="files" :state="Boolean(files.length)" multiple plain></b-form-file>
        </b-form-group>
      </div>

      <div class="my-2 d-flex">
        <slot name="btn-cancel-place" v-bind="{ clearForm }"></slot>

        <b-button
          type="submit"
          variant="success"
          class="ml-auto"
          size="sm"
          :disabled="!isFormFacturasValid"
        >
          Subir
        </b-button>
      </div>
    </b-form>

    <div v-if="response">
      <b-card
          class="mb-1"
          :border-variant="responseIsValid ? 'success' : 'warning'"
          :header-bg-variant="responseIsValid ? 'success' : 'warning'"
          header-text-variant="white"
          :header="responseIsValid ? 'Los comprobantes fiscales enviados se procesaron correctamente':'Estimado usuario, se encontraron las siguientes observaciones en el comprobante fiscal, en breve nos comunicaremos con usted para determinar si cumple con las reglas de operación.'"
          no-body
      >
        <b-card-body
          v-for="row in response.data"
          v-bind:key="row.file"
        >
          <b-row class="mb-2">
            <b-col cols="12" md="12" sm="12">
              <b-card
                v-if="row.result.success"
                :header="`Verificaciones aplicadas al comprobante fiscal ${row.file}`"
                no-body
                :border-variant="row.result.valido ? 'success' : 'danger'"
                header-text-variant="black"
                class="mb-4"
                style="height: 500px; overflow: auto;"
              >
                <b-row>
                  <b-col cols="1"></b-col>
                  <b-col cols="3">
                    <b-form-checkbox class="user-select-none" v-model="bfilter" switch @change="filter = filter === 'No' ? '' : 'No'">
                      <strong>{{ filter === '' ? 'Deseleccionar todo' : 'Seleccionar todo' }}</strong>
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="2">
                    <div class="pt-1">
                      <strong>Válido: {{ row.result.valido }}</strong>
                    </div>
                  </b-col>
                  <b-col cols="2">
                    <div class="pt-1">
                      <strong>Errores: {{ row.result.validations.filter((row) => row.was_applied === 'Si' && row.is_valid === 'No' ).length }}</strong>
                    </div>
                  </b-col>
                </b-row>
                <b-table
                  id="validationsTable"
                  :fields="fields"
                  :items="row.result.validations"
                  striped
                  bordered
                  responsive
                  hover
                  stacked="lg"
                  show-empty
                  small
                  :filter="filter"
                  :filter-function="filterFunction"
                >
                  <template #cell(parent)="row">
                    <span :class="'text-sm text-center w-100' + row.item.is_valid ? '':'text-danger'" >
                      {{ row.item.parent }}
                    </span>
                  </template>
                  <template #cell(estructura.nombre)="row">
                    <span :class="'text-sm text-center w-100' + row.item.is_valid ? '':'text-danger'" variant="danger">
                      {{ row.item.estructura.nombre }}
                    </span>
                  </template>
                  <template #cell(valor)="row">
                    <span :class="'text-sm text-center w-100' + row.item.is_valid ? '':'text-danger'" variant="danger">
                      {{ row.item.valor }}
                    </span>
                  </template>
                  <template #cell(reglas_comprobante)="row">
                    <div v-if="row.item.reglas_comprobante && row.item.reglas_comprobante.length > 0">
                      <ul>
                        <li v-for="regla in filterInvalidRules(row.item.reglas_comprobante)" :key="regla.id">
                          <i v-if="regla.is_valid" data-feather="edit" class="mt-1"></i>
                          <i v-else data-feather="x-square" class="mt-1"></i>
                          {{ regla.regla_estructura.regla.nombre }}: {{ regla.regla_estructura.regla.correccion }}
                          <ul v-if="regla.resultado">
                            <li>{{ regla.resultado }}</li>
                          </ul>
                          <ul v-if="regla.condicion">
                            <li>{{ regla.condicion }}</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div v-else-if="row.item.is_required === 'Si'">
                      <ul>
                        <li>
                          Se requiere la presencia de este atributo en el comprobante
                        </li>
                      </ul>
                    </div>
                  </template>
                </b-table>
              </b-card>
              <b-card
                v-else
                :header="`Verificaciones aplicadas al comprobante fiscal ${row.file}`"
                no-body
                border-variant="danger"
                header-text-variant="black"
                class="mb-4"
                style="height: 131px; overflow: auto;"
              >
                <div class="mt-2">
                  <ul>
                    <li>{{ row.result.error }}</li>
                  </ul>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FacturaInversionistaImport',

  props: {
    dispatchPath: {
      type: String,
      default: 'facturasInversionistasModule/importarFacturas'
    },

    pathPayloadForDispatchAction: {
      type: String,
      default: 'facturasInversionistasModule/getImportFacturas'
    },

    isOnDashboard: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      response: null,
      bfilter: true,
      filter: 'No',
      filterOn: ['is_valid'],
      fields: [
        { key: 'parent.estructura.nombre', label: 'Elemento' },
        { key: 'estructura.nombre', label: 'Atributo' },
        { key: 'valor', label: 'Valor' },
        { key: 'was_applied', label: 'Aplicado' },
        { key: 'is_valid', label: 'Válido' },
        { key: 'is_required', label: 'Requerido' },
        { key: 'reglas_comprobante', label: 'Reglas' }
      ]
    }
  },

  computed: {
    ...mapGetters('facturasInversionistasModule', ['isFormFacturasValid', 'isCreatingResource']),

    responseIsValid () {
      return this.response.data.every((row) => row.result.valido)
    },

    //
    // Guarda y obtiene la información de Vuex
    files: {
      get () {
        return this.$store.state.facturasInversionistasModule.importFacturas.files
      },
      set (value) {
        this.$store.commit('facturasInversionistasModule/setFilesToImport', value)
      }
    }
  },

  methods: {
    async submit () {
      const response = await this.$store.dispatch(
        this.dispatchPath,
        this.$store.getters[this.pathPayloadForDispatchAction]
      )

      this.response = response

      this.$notify(response, 'Importación de facturas')
    },

    clearForm () {
      this.$store.commit('facturasInversionistasModule/setFilesToImport', [])
    },

    splitXML (file) {
      // quita a la variable file la cadena .xml
      return file.replace('.xml', '')
    },

    filterFunction (row, filter) {
      if (!filter || filter === '') {
        return false
      }
      return ((row.is_valid === 'No') && (row.was_applied === 'Si')) ||
        ((row.is_required === 'Si') && (row.is_valid === 'No'))
      // return row.elemento.valido === parseInt(filter)
    },

    filterInvalidRules (rules) {
      return rules.filter((rule) => !rule.is_valid)
    }
  }
}
</script>

<style scoped>
.momentosContables__form {
  /* max-width: 800px; */
  margin: auto;
  min-height: 25vh;
  border: 3px dashed pink;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
