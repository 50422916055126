import Resource from '@/api/Resource'

const API_NAME = '/api/reglas'
const MOD_NAME = 'reglaModule'

const resource = new Resource(API_NAME, MOD_NAME)

export default {
  namespaced: true,

  state: {
    /**
     * Representan todos los permisos disponibles del sistema.
     *
     * Están en un formato para hacer un checklist para poder
     * listar y actualizar fácilmente.
     */
    resource: null
  },

  getters: {
    getData: ({ resource }) => resource ? resource.data : [],

    getResourceList: ({ resource }) => resource ? resource.data : [],

    getAvailableList: state => {
      return resource
    }
  },

  mutations: {
    setResource (state, payload) {
      state.resource = payload
    }
  },

  actions: {
    /**
     * Obtiene todos los permisos.
     *
     * Ejecuta la acción para recuperar todos los permisos,
     * cuando la respuesta es satisfactoria, ejecuta una mutación
     * para almacenarlos localmente.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getResource ({ commit }) {
      let retval = {}

      try {
        const { data } = await resource.get(`${API_NAME}`)
        retval = data
        commit('setResource', data)
      } catch (error) {
        retval = error.response.data
      }

      return retval
    }
  }
}
