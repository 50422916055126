<template>
  <div>
    <portal :to="defaultNotificationalertPlace">
      <notification-alert ref="notificationAlert"></notification-alert>
    </portal>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EventBus } from '@/EventBus'

const DEFAULT_NOTIFICATION_ALERT_PLACE = 'notificationAlertPlace'

export default {
  name: 'App',

  created () {
    /**
     * DEPRECATED!!!.
     *
     * TODO: DELETE.
     *
     * Evento para notificación de tipo Toast.
     *
     * Está a la escucha del evento 'toast', para agregar una notificación,
     * en la espera de ser procesada.
     */
    EventBus.$on('toast', this.toast)

    /**
     * Evento para la notificación de tipo Alert.
     *
     * Está a la escucha del evento 'alert', al ejecutarse se muestra el componente
     * con sus respectivas propiedades, este componente ejecuta una sola instancia,
     * es decir, no agrega notificaciones a una cola como las notificaciones 'toast',
     * simplente es una instancia, si se lanza otro mensaje posterior, la información
     * de la alerta se sobreescrirá por los nuevos valores.
     */
    EventBus.$on('alert', this.alert)

    EventBus.$on('clear-alert', this.clearAlert)
  },

  data () {
    return {
      defaultNotificationalertPlace: DEFAULT_NOTIFICATION_ALERT_PLACE
    }
  },

  computed: {
    ...mapGetters('UIModule', {
      notifications: 'getNotifications',
      unreadNotifications: 'getUnReadNotifications',
      isThereNotifications: 'isThereNotifications',
      isAllNotificationsShowed: 'isAllNotificationsShowed',
      isLoadingNotification: 'isLoadingNotification'
    })
  },

  watch: {
    unreadNotifications (unread) {
      if (!this.isThereNotifications) return

      //
      // Muestra todas las notificaciones sin leer si no se han mostrado.
      if (!this.isAllNotificationsShowed) {
        unread.forEach(e =>
          this.$delay(2000).then(() =>
            this.$notify({ error: false, message: e.content }, { delay: 9000 })
          )
        )

        this.$store.dispatch('UIModule/markAllNotificationsAsShowed')

        return
      }

      //
      // Se debe de preguntar si el cambio se debe a que se está marcando
      // la notificación como leída, porque esto afecta a las notificaciones no leídas
      if (this.isLoadingNotification) return

      //
      // Si ya se mostraron todas, sólo muestra la última notificación creada
      this.$notify({ error: false, message: unread[0].content }, { delay: 9000 })
    }
  },

  beforeCreate () {
    this.$OneSignal.showSlidedownPrompt()
  },

  methods: {
    ...mapActions('UIModule', ['bindNotifications']),

    /**
     * DEPRECATED!!!.
     *
     * TODO: DELETE.
     *
     * Notifica al usuario creando una nueva instancia del componente
     * de notification-toast, esta notificación se va apilando en una
     * lista de notificaciones que se deben de resolver desde Vuex.
     *
     * @param {string} title Título principal de la notificación
     * @param {Object} getval Otros valores de la notificación
     */
    toast (title, message, attr = {}) {
      return this.$notify({ message }, title, attr)
    },

    /**
     * Muestra una notificación de tipo alerta.
     *
     * Crea una instancia del comopnente de NotificationAlert.
     *
     * @param {string} title Título principal de la notificación.
     * @param {string} message Mensaje principal mostrado en el cuerpo de la notificación.
     * @param {Object} attr Atributos que se asignarán al componente de notificación.
     * @param {string} openPortalToName Muestra la notificación en un lugar designado.
     *                                  Nota: Esta ventana de portal debe de estar instanciada
     *                                        y debe de coincidir con el nombre indicado.
     */
    alert (title, message, attr = {}, openPortalToName) {
      //
      // Elige dónde se mostrará esta notificación
      // abriendo un portal al nombre del lugar indicado.
      this.defaultNotificationalertPlace = openPortalToName
      // console.log('this.defaultNotificationalertPlace :>> ', this.defaultNotificationalertPlace)

      const attributes = this.getNotificationParams(attr)

      this.$delay(100).then(_ =>
        EventBus.$emit('on-show-notification-alert', { title, message, ...attributes })
      )
    },

    getNotificationParams (attr) {
      // console.log('attr :>> ', JSON.stringify(attr, null, 4))

      const obj = {}

      if (attr.icon === undefined) obj.icon = 'info'
      if (attr.status === undefined) obj.status = 'info'
      if (attr.closeable === undefined) obj.closeable = true
      if (attr.closeOnTimeout === undefined) obj.closeOnTimeout = true

      const retval = { ...obj, ...attr }

      // console.log('retval :>> ', JSON.stringify(retval, null, 4))

      return retval
    },

    clearAlert () {
      if (!this.$refs.notificationAlert) return

      this.$refs.notificationAlert.close()
    }
  }
}
</script>
